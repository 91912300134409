<template lang="pug">
svg(
  xmlns="http://www.w3.org/2000/svg"
  width="38"
  height="38"
  viewBox="0 0 38 23"
  @mouseover="onOver($event)"
  @mouseout="onOut($event)"
)
  rect(
    height="100%"
    width="100%"
    style="fill:none"
  )
  g(transform="translate(1.5 1.5)")
    line#line1(
      data-name="Line 1"
      x2="35"
      transform="translate(0)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-width="3"
    )
    line#line2(
      data-name="Line 2"
      x2="30"
      transform="translate(5 10)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-width="3"
    )
    line#line3(
      data-name="Line 3"
      x2="25"
      transform="translate(10 20)"
      fill="none"
      stroke="#fff"
      stroke-linecap="round"
      stroke-width="3"
    )
</template>

<script>
export default {
  mounted () {
    const burgerchange1 = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#line1', { css: { stroke: '#000' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })
    const burgerchange2 = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#line2', { css: { stroke: '#000' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })
    const burgerchange3 = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#line3', { css: { stroke: '#000' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    this.$scrollmagic.addScene(burgerchange1)
    this.$scrollmagic.addScene(burgerchange2)
    this.$scrollmagic.addScene(burgerchange3)
  },
  methods: {
    onOver: function (event) {
      // console.log(event)
    },
    onOut: function (event) {
      // console.log(event)
    }
  }
}
</script>
