<template lang="pug">
  section.body
    .rows.boxed.chip-rows
      .row.chip-row
          h4.chip User Research
          h4.chip Personas
          h4.chip User eXperience design (UX)
          h4.chip User Interface design (UI)
          h4.chip Prototipo usabilità
          h4.chip App iOS native (Swift)
          h4.chip App Android native (Kotlin)
          h4.chip Design System
          h4.chip Tailwind.css
          h4.chip Vue.js
          h4.chip Node.js
          h4.chip Google Cloud
          h4.chip App ibride (Ionic)
          h4.chip Test di prodotto

</template>

<script>
export default {
  name: 'Chips',

  mounted () {
    const thirdillustration = this.$scrollmagic
      .scene({
        triggerHook: 1,
        duration: 700,
        offset: 300
      })
      .setTween('', { css: { left: 0, opacity: 0.7 } })

    this.$scrollmagic.addScene(thirdillustration)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

.body {
  z-index: 1;
  height: auto;
  width: 100vw;
  background-color: transparent;
  margin-top: 100px !important;
  padding-bottom: 100px !important;
}

.chip-row{
      margin: auto;
    width: auto;
    z-index: 1;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    display: flex;
    flex-flow: wrap;
}

h4{
  font-weight: 600 !important;
}

.chip{
    color: rgba(0,0,0,1);
    background: rgba(255,255,255,1);
    padding: 15px 30px;
    border: solid 3px #000;
    border-radius: 40px;
    margin-bottom: 20px !important;
    max-width: fit-content;
    margin-right: 1%;
    min-width: fit-content;
}

</style>
