<template lang="pug">
header
  nav.columns.navbar-columns
    .left.column.has-text-left.is-one-quarter
      router-link(to="/")
        LogoNew
    .right.column
      .has-text-right.hide-on-mobile.menunavbar
        .router
          router-link(to="/mobile-app").menunav Applicazioni Mobile
          .underline
        .router
          router-link(to="/digitalization").menunav Digitalizzazione
          .underline
        .router
          .dropdown.is-hoverable
            .dropdown-trigger
              .menunav.has-dropdown(aria-haspopup='true' aria-controls='dropdown-menu')
                span Prodotti
            #dropdown-menu.dropdown-menu(role='menu')
              .dropdown-content
                a.dropdown-item(href='https://inksquad.com/' target="_blank")
                  | Inksquad
        .router
        a.special-menunav.last-element(href="#form" v-smooth-scroll updateHistory: false) Contattaci
      .burger.has-text-right.hide-on-mobile-landscape.burger-item(@click="toggleModal()")
        BurgerIcon(v-if="!this.showMenu")
        XIcon(v-else="this.showMenu")

    transition(name="fade")
      Menu#menu(
        v-if="showMenu"
        @toggle="toggleModal()"
      )
        div(slot="header") Hello Modal
        div(slot="body")
          | Typewriter swag pabst fam green juice. Echo park kogi subway tile, listicle etsy hell of sriracha actually. Viral health goth leggings, man bun intelligentsia 90's freegan craft beer food truck ugh venmo. Keytar mumblecore master cleanse, chambray woke letterpress +1 iceland dreamcatcher.
        div(slot="footer")
          button(@click="toggleModal()") ok
</template>

<script>
import LogoNew from '@/components/LogoNew.vue'
import BurgerIcon from '@/components/BurgerIcon.vue'
import Menu from '@/components/Menu.vue'
import XIcon from '@/components/XIcon.vue'

export default {
  name: 'Navbar',

  components: { LogoNew, BurgerIcon, Menu, XIcon },

  props: {
    isMobileLandscape: { type: Boolean }
  },

  data () {
    return {
      showMenu: false
    }
  },

  watch: {
    '$route' () {
      this.showMenu = false
    },
    'isMobileLandscape' (condition) {
      // If the view is mobile landscape, we force the menu to be closed
      if (condition) {
        this.showMenu = false
      }
    }
  },

  mounted () {
    const fixedmenu = this.$scrollmagic.scene({
      triggerHook: 1,
      duration: 100,
      offset: 700
    })
      .setTween('.menunav', { css: { color: 'rgba(0,0,0,1)' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    const specialfixedmenu = this.$scrollmagic.scene({
      triggerHook: 1,
      duration: 100,
      offset: 700
    })
      .setTween('.special-menunav', { css: { color: 'rgba(0,0,0,1)', 'border-color': 'rgba(0,0,0,1)', background: 'rgba(0,0,0,1)' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    const whitebar = this.$scrollmagic.scene({
      triggerHook: 1,
      duration: 100,
      offset: 700
    })
      .setTween('.navbar-columns', { css: { background: 'rgba(255,255,255,1)' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    // Attaching scrollmagic controller to element

    const underline = this.$scrollmagic.scene({
      triggerHook: 1,
      duration: 100,
      offset: 700
    })
      .setTween('.underline', { css: { background: 'rgba(0,0,0,1)' } })

    this.$scrollmagic.addScene(underline)
    this.$scrollmagic.addScene(fixedmenu)
    this.$scrollmagic.addScene(whitebar)
    this.$scrollmagic.addScene(specialfixedmenu)
  },

  methods: {
    toggleModal: function () {
      // console.log('toggle menu')
      this.showMenu = !this.showMenu
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

.menunavbar{
  display: -webkit-inline-box;
  align-items: center;
    text-align: right;
}

.last-element{
  margin-left: 20px !important;
}

@media screen and (max-width: 1025px) {
.menunavbar{
    display: none;
  }
}

@media screen and (min-width: 1025px) {
  .burger-item {
    display: none !important;
  }
}

.hide-on-desktop{
  margin-top: -5px;
}

.menunav {
  margin: auto 20px;
  font-weight: bolder;
  font-size: 16px !important;
  color: rgba(255,255,255,1);
  cursor: pointer;
}

.dropdown-item {
  color: black;
  font-weight: bolder;
  text-align: left !important;
  padding-left: 20px;
  cursor: pointer;
  padding-right: 30px !important;
}

a.menunav {
    margin: auto 20px;
    font-weight: bolder;
    font-size: 16px !important;
    color: rgba(255,255,255,1);
    cursor: pointer;
}

a.special-menunav {
    margin: auto 0px;
    font-weight: bolder;
    font-size: 16px !important;
    color: rgba(255,255,255,1) !important;
    cursor: pointer;
    background: rgba(255,255,255,0);
    padding: 15px 30px;
    border: solid 3px;
    border-color: rgba(255,255,255,1);
    border-radius: 40px;
}

a.special-menunav:hover {
    color: rgba(0,0,0,1) !important;
    background: rgba(255,255,255,1) !important;
}

a.menunav.router-link-exact-active.router-link-active {
    font-weight: 900;
}

nav.navbar-columns {
  width: 100vw;
  display: flex;
  padding: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  position: fixed;
  top: 0;
  margin: 0px;
  z-index: 999999;
  background: rgba(0,0,0,0);

  @include tablet {
    padding-left: 76px;
    padding-right: 76px;
  }

  @include desktop {
    padding-left: 80px;
    padding-right: 80px;
  }

  @include fullhd {
    padding-left: 100px;
    padding-right: 100px;
  }

  @include widescreen {
    padding-left: 100px;
    padding-right: 100px;
  }

  @include device(iphone-landscape) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .left {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .right {

    align-self: center;
    text-align: -webkit-right;
    .burger {
      display: flex;
      align-items: center;
      height: 100%;
      float: right;
      cursor: pointer;
    }

    margin-top: -8px;

    a {
      font-weight: bolder;
      font-size: 12px;
    }
  }

  .column {
    padding: 0px;
    z-index: 3;
  }

  #menu {
    z-index: 2;
  }
}

.router{
  cursor: pointer;
.underline, .smaller-underline{
    height: 3px;
    width: 0%;
    background: rgba(255,255,255,1);
    transition: 0.2s ease all .3s;
    position: relative;
    top: 3px;
    margin: auto;
}
}

.router:hover{

.underline{
    width: 80%;
   transition: 0.3s cubic-bezier(.47,1.64,.41,.8);
}

.smaller-underline{
    width: 35%;
   transition: 0.3s cubic-bezier(.47,1.64,.41,.8);
}
}
</style>
