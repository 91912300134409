import { render, staticRenderFns } from "./FooterLogo.vue?vue&type=template&id=1ce973b6&scoped=true&lang=pug&"
import script from "./FooterLogo.vue?vue&type=script&lang=js&"
export * from "./FooterLogo.vue?vue&type=script&lang=js&"
import style0 from "./FooterLogo.vue?vue&type=style&index=0&id=1ce973b6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ce973b6",
  null
  
)

export default component.exports