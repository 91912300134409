<template lang="pug">
#app
  resize-observer(@notify="resize")
  Navbar(:isMobileLandscape="isMobileLandscape")
  router-view
  BottomBar(
    :isMobile="isMobile",
    :isMobileLandscape="isMobileLandscape",
    :isMobilePortrait="isMobilePortrait"
  )
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import BottomBar from '@/components/BottomBar.vue'
import scssVariables from '@/assets/scss/_variables.scss'
import { ResizeObserver } from 'vue-resize'
// eslint-disable-next-line eqeqeq
const scssTableWidth = scssVariables != undefined && scssVariables.tabletWidth != undefined
  ? scssVariables.tabletWidth
  : '567px'

export default {
  name: 'App',

  components: { ResizeObserver, Navbar, BottomBar },

  data () {
    return {
      isMobile: true,
      isMobileLandscape: true,
      isMobilePortrait: true
    }
  },

  mounted: function () {
    this.isMobilePortrait = this.computeIsMobilePortrait()
    this.isMobileLandscape = this.computeIsMobileLandscape()
    this.isMobile = this.isMobileLandscape || this.isMobilePortrait
  },

  methods: {
    computeIsMobileLandscape: function () {
      const tabletWidthNumber = Number(String(scssTableWidth).replace('px', ''))
      return window.innerHeight < tabletWidthNumber && window.innerHeight < window.innerWidth
    },
    computeIsMobilePortrait: function () {
      const tabletWidthNumber = Number(String(scssTableWidth).replace('px', ''))
      return window.innerWidth < tabletWidthNumber && window.innerHeight > window.innerWidth
    },
    resize () {
      this.isMobilePortrait = this.computeIsMobilePortrait()
      this.isMobileLandscape = this.computeIsMobileLandscape()
      this.isMobile = this.isMobileLandscape || this.isMobilePortrait
      // console.log('resized')
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/app.scss";

html {
  background-color: black !important;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body {
  overflow: hidden;
  width: 100vw !important;
  overflow-x: hidden !important;
  height: 100%;
}

#app {
  background-color: transparent;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* make room for the notch at the sides */
html[data-notch="true"][data-orientation="landscape"] body {
  padding-left: 44px;
  padding-right: 44px;
  width: calc(100% - 44px - 44px);
}

html {
  // your css to create a cover image
  padding: env(safe-area-inset); // <- this is the missing piece. Add it.
}

.hero-body {
    padding: 0px !important;
}

@font-face {
  font-family: "San Francisco";
  src: local("San Francisco"),
    url(./assets/fonts/SF-Pro-Text-Regular.otf) format("truetype");
}

@font-face {
  font-family: "San Francisco Bold";
  src: local("San Francisco Bold"),
    url(./assets/fonts/SF-Pro-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "San Francisco Black";
  src: local("San Francisco Black"),
    url(./assets/fonts/SF-Pro-Text-Black.ttf) format("truetype");
}

h1, h2 {
  font-family: 'San Francisco Black';
}
h3, h4 {
  font-family: 'San Francisco Bold';
}

</style>
