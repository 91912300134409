<template lang="pug">
svg(
  data-name="Raggruppa 301",
  xmlns="http://www.w3.org/2000/svg",
  width="222",
  height="57",
  viewbox="0 0 222 57"
)
  g#text(data-name="Text", transform="translate(74 4)")
    text#company(
      transform="translate(0 22)",
      :style="{ fill: companyNameColor }",
      font-size="20",
      font-family="Mont",
      font-weight="bold",
      letter-spacing="0.6px",
      data-name="Company name"
    )
      tspan(x="0", y="0") SMARTSQUAD
    text#motto(
      data-name="Company motto",
      transform="translate(0 38)",
      :style="{ fill: companyMottoColor }",
      font-size="11",
      font-family="Mont",
      font-weight="medium",
      letter-spacing="0.16em"
    )
      tspan#raquo(x="0", y="0" style="color: #3FB498 !important;") &raquo;
      tspan#mottophrase(x="16", y="0", xml:space="preserve") app mobile e web

  g#logo(data-name='Logo' transform='translate(-6160 -4739)')
    path#Tracciato_27(data-name='Tracciato 27' d='M26.037.148.619,13.271A1.139,1.139,0,0,0,0,14.289L.126,27.822a1.05,1.05,0,0,0,.567.913L12.11,34.452a.525.525,0,0,1,0,.961L.892,41.155a1.517,1.517,0,0,0,0,2.7L25.785,56.53a2.226,2.226,0,0,0,2.026,0L52.635,43.985a1.517,1.517,0,0,0,.835-1.36l-.047-14.8a1.265,1.265,0,0,0-.693-1.129L42.446,21.481a.451.451,0,0,1,0-.8l11.129-5.7a.777.777,0,0,0,0-1.386L27.233.142a1.318,1.318,0,0,0-1.2.005Z' transform='translate(6160 4738.999)' fill='#fff')
    path#Tracciato_29(data-name='Tracciato 29' d='M11.756,36.213a.619.619,0,0,0,.367-.567.63.63,0,0,0-.352-.572L4.344,31.368A.609.609,0,0,0,4.06,31.3a.651.651,0,0,0-.336.094.635.635,0,0,0-.3.525v7.433a.635.635,0,0,0,.924.567Z' transform='translate(6158.375 4724.13)' :style="{ fill: logoContentColor }")
    path#Tracciato_31(data-name='Tracciato 31' d='M53.01,47.247a.63.63,0,0,0-.352-.572L42.743,41.72a.635.635,0,0,0-.567,0L29.488,48.05a.871.871,0,0,1-.782,0L16.06,41.725a.619.619,0,0,0-.567,0L5.562,46.691a.63.63,0,0,0-.352.572.619.619,0,0,0,.352.567L28.8,59.462a.646.646,0,0,0,.572,0L52.658,47.819a.63.63,0,0,0,.352-.572' transform='translate(6157.525 4719.211)' :style="{ fill: logoContentColor }")
    path#Tracciato_30(data-name='Tracciato 30' d='M17.02,18.061,41.666,30.444,46.547,28,21.9,15.62Z' transform='translate(6151.915 4731.579)' :style="{ fill: logoContentColor }")
    path#Tracciato_32(data-name='Tracciato 32' d='M30.24,11.769,54.886,24.152l4.184-2.094L34.424,9.68Z' transform='translate(6145.634 4734.401)' :style="{ fill: logoContentColor }")
    path#Tracciato_33(data-name='Tracciato 33' d='M46.949,3.61a.646.646,0,0,0-.572,0L42.13,5.735,66.776,18.113l3.449-1.732a.619.619,0,0,0,.352-.567.63.63,0,0,0-.352-.572Z' transform='translate(6139.985 4737.316)' :style="{ fill: logoContentColor }")
    path#Tracciato_28(data-name='Tracciato 28' d='M28.826,36.912a.646.646,0,0,0,.572,0l4.6-2.3L9.33,22.23l-3.769,1.9a.64.64,0,0,0,0,1.144Z' transform='translate(6157.526 4728.438)' :style="{ fill: logoContentColor }")
    path#Tracciato_35(data-name='Tracciato 35' d='M24.223,68.76a.635.635,0,0,0-.567,0L18.8,71.185l15.853,7.953V74.361a.646.646,0,0,0-.352-.572Z' transform='translate(6151.069 4706.366)' :style="{ fill: logoContentColor }")
    path#Tracciato_36(data-name='Tracciato 36' d='M5.561,77.762a.64.64,0,0,0,0,1.144l21.685,10.84a.635.635,0,0,0,.95-.572V84.387L10.285,75.4Z' transform='translate(6157.526 4703.179)' :style="{ fill: logoContentColor }")
    path#Tracciato_34(data-name='Tracciato 34' d='M75.594,56.775l-22.572,11.3a.625.625,0,0,0-.352.572V80.281a.625.625,0,0,0,.3.525.635.635,0,0,0,.625.031L76.166,69.546a.635.635,0,0,0,.352-.572V57.347a.63.63,0,0,0-.3-.525.646.646,0,0,0-.625-.031' transform='translate(6134.978 4712.051)' :style="{ fill: logoContentColor }")
</template>

<script>
import scssVariables from '@/assets/scss/_variables.scss'

export default {
  name: 'Logo',

  props: {
    logoContentColor: {
      type: String,
      // eslint-disable-next-line eqeqeq
      default: scssVariables != undefined ? scssVariables.smartSquadColor : '#3FB498'
    },
    logoOutlineColor: {
      type: String,
      default: '#fff'
    },
    companyNameColor: {
      type: String,
      default: '#fff'
    },
    companyMottoColor: {
      type: String,
      default: '#fff'
    }
  },
  mounted () {
    const logochange1 = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#company', { css: { fill: '#000' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })
    const logochange2 = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#mottophrase', { css: { fill: '#000' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })
    const raquochange = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#raquo', { css: { fill: '#3FB498' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    this.$scrollmagic.addScene(logochange1)
    this.$scrollmagic.addScene(raquochange)
    this.$scrollmagic.addScene(logochange2)
  }
}

</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

svg {
  @include mobile {
    width: 60px !important;
    overflow: visible;
  }
}

</style>
