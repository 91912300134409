import scssDevices from '@/assets/scss/_devices.scss'

const VueDevices = {
  install (Vue) {
    const devices = {
      methods: {
        isLandscape (minIphoneWidth, maxIphoneWidth) {
          let query = '(orientation: landscape)'

          if (maxIphoneWidth != null) {
            query += 'and (max-width: ' + maxIphoneWidth + ')'
          }
          if (minIphoneWidth != null) {
            query += 'and (min-width: ' + minIphoneWidth + ')'
          }
          return window && window.matchMedia(query).matches
        },

        isPortrait (minIphoneWidth, maxIphoneWidth) {
          let query = '(orientation: portrait)'

          if (maxIphoneWidth != null) {
            query += 'and (max-width: ' + maxIphoneWidth + ')'
          }
          if (minIphoneWidth != null) {
            query += 'and (min-width: ' + minIphoneWidth + ')'
          }
          return window && window.matchMedia(query).matches
        },

        isIphoneLandscape () {
          return this.isLandscape(scssDevices.iPhoneLandscapeMinWidth, scssDevices.iPhoneLandscapeMaxWidth)
        },

        isIphonePortrait () {
          return this.isPortrait(scssDevices.iPhonePortraitMinWidth, scssDevices.iPhonePortraitMaxWidth)
        },

        isIphone () { return this.isIphoneLandscape() || this.isIphonePortrait() },

        isIpadLandscape () {
          return this.isLandscape(scssDevices.iPadLandscapeMinWidth, scssDevices.iPadLandscapeMaxWidth)
        },

        isIpadPortrait () {
          return this.isPortrait(scssDevices.iPadPortraitMinWidth, scssDevices.iPadPortraitMaxWidth)
        },

        isIpad () { return this.isIpadLandscape() || this.isIpadPortrait() }
      }
    }

    Vue.prototype.$devices = new Vue(devices)
  }
}

export default VueDevices

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(VueDevices)
}
