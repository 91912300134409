<template lang="pug">
svg(xmlns='http://www.w3.org/2000/svg' width='40.005' height='31.913' viewbox='0 0 40.005 31.913')
  g#Raggruppa_359(data-name='Raggruppa 359' transform='translate(265 3136.958)')
    path#Tracciato_39(
      data-name='Tracciato 39'
      d='M26,5.964v28a1.975,1.975,0,0,1-3.06,1.66L11.4,27.967H6a4,4,0,0,1-4-4v-8a4,4,0,0,1,4-4h5.4L22.9,4.3A2.109,2.109,0,0,1,26,5.964Z'
      transform='translate(-267 -3140.994)'
      fill='#fff'
    )
    rect#Rettangolo_3(
      v-if="isActive"
      data-name='Rettangolo 3'
      width='4'
      height='12.001'
      transform='translate(-236.997 -3127.029)'
      fill='#fff'
       rx="2"
    )
    rect#Rettangolo_4(
      v-if="isActive"
      data-name='Rettangolo 4'
      width='4'
      height='28.003'
      transform='translate(-228.996 -3135.03)'
      fill='#fff'
       rx="2"
    )
    path#Tracciato_37(
      v-if="!isActive"
      data-name='Tracciato 37'
      d='M20.607,13.495l1.947-1.932a1.5,1.5,0,1,0-2.126-2.126L18.5,11.383,16.564,9.436a1.5,1.5,0,0,0-2.126,2.126l1.947,1.932-1.947,1.932a1.5,1.5,0,1,0,2.126,2.126L18.5,15.606l1.932,1.947a1.5,1.5,0,1,0,2.126-2.126Z'
      transform='translate(-248.996 -3135.03)'
      fill='#fff'
    )
</template>

<script>
export default {
  name: 'SoundIcon',

  props: {
    isActive: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    const Soundchange1 = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#Tracciato_39', { css: { fill: '#000' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })
    const Soundchange2 = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#Rettangolo_3', { css: { fill: '#000' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })
    const Soundchange3 = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#Rettangolo_4', { css: { fill: '#000' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    const Soundchange4 = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#Tracciato_37', { css: { fill: '#000' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    this.$scrollmagic.addScene(Soundchange1)
    this.$scrollmagic.addScene(Soundchange2)
    this.$scrollmagic.addScene(Soundchange3)
    this.$scrollmagic.addScene(Soundchange4)
  },
  updated () {
    const Soundchange1 = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#Tracciato_39', { css: { fill: '#000' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })
    const Soundchange2 = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#Rettangolo_3', { css: { fill: '#000' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })
    const Soundchange3 = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#Rettangolo_4', { css: { fill: '#000' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    const Soundchange4 = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#Tracciato_37', { css: { fill: '#000' } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    this.$scrollmagic.addScene(Soundchange1)
    this.$scrollmagic.addScene(Soundchange2)
    this.$scrollmagic.addScene(Soundchange3)
    this.$scrollmagic.addScene(Soundchange4)
  }
}
</script>
