import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import * as VueThreejs from 'vue-threejs'
import VueResize from 'vue-resize'
import './assets/scss/app.scss'
import VueMeta from 'vue-meta'
import VueScrollmagic from 'vue-scrollmagic'
import 'gsap'
import IdleVue from 'idle-vue'
import VueDevices from './utils/device'
import VueGtm from '@gtm-support/vue2-gtm'
import { init } from 'emailjs-com'
import VueSmoothScroll from 'vue3-smooth-scroll'

// Bulma
Vue.use(Buefy)

// ThreeJs
Vue.use(VueThreejs)

// Vue Resize
Vue.use(VueResize)

// Vue Meta
Vue.use(VueMeta)

// Vue smooth scroll
Vue.use(VueSmoothScroll)

// Vue Scroll Magic
Vue.use(
  VueScrollmagic,
  {
    vertical: true,
    horizontal: false,
    globalSceneOptions: {},
    loglevel: 0, //  2,
    refreshInterval: 100
  }
)

init('user_YUAS7e13BaBup1jL73EAS')

// Vue Idle
const eventsHub = new Vue()
Vue.use(
  IdleVue,
  {
    idleTime: 60000, // 2 minute
    eventEmitter: eventsHub,
    startAtIdle: false,
    events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll']
  }
)

// Vue Configurations
Vue.config.productionTip = process.env.NODE_ENV === 'development'

// Vue Devices
Vue.use(VueDevices)

// Vue Google Tag Manager
Vue.use(VueGtm, {
  id: 'GTM-MDGKGX9', // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  queryParams: {
    // Add url query string when load gtm.js with GTM ID (optional)
    gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    gtm_preview: 'env-4',
    gtm_cookies_win: 'x'
  },
  defer: true, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: process.env.NODE_ENV === 'development', // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
