<template lang="pug">
  #process
    #white
    StarsCanvas(
      :id="starsCanvasId"
      :animation="animation",
      :animationSpeed="animationSpeed"
    )

    //- ScrollIcon.has-text-centered.hide-on-mobile-landscape#scroll-icon

    Header2Section.smaller-sky(
      id="section1",
      h2="Il nostro processo creativo progettuale"
    )
    .white-bg.hide-on-mobile-landscape
      ColumnsSection.hide-on-mobile-landscape
      Chips

      #form.gif.hide-on-mobile-landscape(ref="form")
        img.bottle(:src="bottle")
      ContactForm.hide-on-mobile-landscape
      Footer.hide-on-mobile-landscape

</template>

<script>
import Chips from '@/components/process/Chips.vue'
import ColumnsSection from '@/components/process/ColumnsSection.vue'
import gif from '../assets/images/BOTTLES.gif'
import StarsCanvas from '@/components/3d/StarsCanvas.vue'
import ContactForm from '@/components/shared/ContactForm.vue'
import Header2Section from '@/components/shared/Header2Section.vue'
import Step2 from '@/components/mobile-app/Step2.vue'
import Header2BiggerSection from '@/components/shared/Header2BiggerSection.vue'
import Footer from '@/components/shared/Footer.vue'
import ScrollIcon from '@/components/ScrollIcon.vue'

export default {
  name: 'Process',

  components: {
    // Mobile App components
    StarsCanvas,
    ColumnsSection,
    Header2Section,
    Chips,
    Step2,
    Header2BiggerSection,
    Footer,
    ContactForm,

    // Global components
    ScrollIcon
  },

  data () {
    return {
      animation: true,
      animationSpeed: 1,
      starsCanvasId: 'mobileapp-stars-canvas',
      bottle: gif
    }
  },

  mounted () {
    const localThis = this

    const mobileAppScene1 = this.$scrollmagic.scene({
      triggerElement: '#mobile-app',
      triggerHook: 0,
      offset: -1,
      duration: this.$el.clientHeight / 3
    })
      .on('enter leave', function (e) {
        localThis.animation = e.type === 'enter'
      })
      .on('progress', function (e) {
        localThis.animationSpeed = 1 - e.progress
      })
      // .addIndicators({ name: `Home - Scene 1 (duration: ${this.$el.clientHeight / 3})` })

    const mobileAppScene2 = this.$scrollmagic.scene({
      triggerHook: 0.5,
      duration: 300,
      offset: 150
    })
      .setTween('#scroll-icon', { css: { opacity: 0 } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    // const whitebackground = this.$scrollmagic.scene({
    //   triggerHook: 0.8,
    //   duration: 500,
    //   offset: 150
    // })
    //   .setTween('#white', { css: { opacity: 0 } })
    // .addIndicators({ name: 'Step 0 (duration: 300)' })
    const schemeMovment = this.$scrollmagic
      .scene({
        triggerElement: '#section2',
        triggerHook: 0,
        duration: 500,
        offset: 0
      })
      .setTween('.scheme', { css: { top: 0 } })
    // .addIndicators({ name: 'Step 0 (duration: 300)' })
    const schemeOpacity = this.$scrollmagic
      .scene({
        triggerElement: '.scheme',
        triggerHook: 1,
        duration: 500,
        offset: 80
      })
      .setTween('.scheme', { css: { opacity: 1 } })

    this.$scrollmagic.addScene(mobileAppScene1)
    this.$scrollmagic.addScene(mobileAppScene2)
    // this.$scrollmagic.addScene(whitebackground)
    this.$scrollmagic.addScene(schemeMovment)
    this.$scrollmagic.addScene(schemeOpacity)
  },

  metaInfo () {
    return {
      title: 'Smart Squad Srl » Sviluppo App & Web',
      seoTitle: 'mobile-app'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

  .bottle{
    position: relative;
    z-index: 1;
    width: 170px;
    transform: rotate(45deg);
  }

#process {
  overflow: hidden;
  background: #fff;

  .white-bg{
    background: #fff !important;
    position: sticky;
  }

  .smaller-sky{
    height: 75% !important;

      @include device(iphone-landscape) {
    height: 100% !important;
  }
  }

   #white {
    background: #fff;
    z-index: 1;
    opacity: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: none;

       @include device(iphone-portrait) {
    display: block;
    position: absolute;
  }
  @include device(iphone-landscape) {
    display: block;
    position: absolute;
  }

         @include device(ipad-portrait) {
    display: block;
    position: absolute;
  }
  @include device(ipad-landscape) {
    display: block;
    position: absolute;
  }
  }

  #section1 {
    height: 75vh !important;
          @include mobile {
    height: 100vh !important;
  }
          @include device(iphone-landscape)  {
    height: 100vh !important;
  }
  }

  .hero.is-fullheight {
    min-height: 0vh !important;
  }

  .hero.is-halfheight .hero-body,
  .hero.is-fullheight .hero-body,
  .hero.is-fullheight-with-navbar .hero-body {
    align-items: start !important;
    display: flex;
  }

  .resize-observer {
    position: absolute;
  }

  .canvas {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 0;
    top: 0px;
    opacity: 1;
    background: #000;
  }

  #scroll-icon {
    z-index: 2;
    position: fixed;
    bottom: 20px;
    display: inline-flex;
    // margin-left: -20px;
    left: calc(50% -  17px);
  }

  #section3 {
    margin-top: 0px;
    margin-bottom: 100px;
  }

    #section2 {
      padding-top: 100px;
  }

}

.hide-on-mobile{

      @include device(iphone-portrait) {
display: none;
  }
  @include device(iphone-landscape) {
display: none;
  }
}
</style>
