<template lang="pug">
  section.body(:id="id")
    .main.has-text-black.has-text-left
      h2.is-uppercase.has-text-black.has-text-left.boxed
        span(
          v-for="(value, index) in h2s",
          :key="index",
          :id="`${id}-h2-${index}`",
          v-html="value"
        )
      h3.has-text-black.has-text-left.boxed
        span(
          v-for="(value, index) in h3s",
          :key="index",
          :id="`${id}-h2-${index}`",
          v-html="value"
        )
      p.boxed.has-text-black(
        v-for="(value, index) in ps",
        :key="index",
        :id="`${id}-p-${index}`",
        v-html="value",
        :ref="`${id}-p-${index}`"
      )
</template>

<script>
import { isMobile } from '@/utils/isMobile'

export default {

  name: 'NoOctaContentSection',

  props: {
    id: {
      type: String,
      default: 'example-h2-bigger'
    },
    h2s: {
      type: Array,
      default: () => ['Title 1', 'Title 2']
    },
    ps: {
      type: Array,
      default: () => ['P Content section 1.', 'Another P']
    },
    h3s: {
      type: Array,
      default: () => ['']
    }
  },

  data () {
    return {
      scenes: []
    }
  },

  mounted () {
    let h2Duration = this.$el.clientHeight / 5
    const h2Offset = this.$el.clientHeight / 5
    const h2StartingDurationStep = ((this.$el.clientHeight / 5) * 2) / Object.keys(this.h2s).length

    // if (isMobile()) {

    // }
    let h2DurationStep = h2StartingDurationStep
    for (const index of Object.keys(this.h2s)) {
      h2Duration += h2DurationStep
      const scene = this.$scrollmagic.scene({
        triggerElement: isMobile() ? '' : `#${this.id}`,
        triggerHook: 0.8,
        duration: h2Duration,
        offset: (h2Offset - 350)
      })
        .setTween(
          `#${this.id} #${this.id}-h2-${index}`,
          { css: this.$devices.isIphone() ? { opacity: 1 } : { left: 0 } }
        )
        // .addIndicators({ name: `#${this.id} - Scene ${index + 1} (duration: ${h2Duration})` })

      this.$scrollmagic.addScene(scene)
      this.scenes.push(scene)
    }

    const pStartingOffset = h2Offset
    const pDuration = this.$el.clientHeight / 3
    let pOffset = pStartingOffset
    for (const index of Object.keys(this.ps)) {
      pOffset += this.$refs[`${this.id}-p-${index}`][0].clientHeight
      // console.log(`#${this.id} ${this.id}-p-${index} - ${pOffset}`)
      const scene = this.$scrollmagic.scene({
        triggerElement: `#${this.id}`,
        triggerHook: 1,
        offset: 0,
        duration: pDuration
      })
        .setTween(`#${this.id} #${this.id}-p-${index}`, { css: { opacity: 1 } })
        // .addIndicators({ name: `#${this.id} - Scene ${index + 1} (duration: ${pDuration})` })

      this.$scrollmagic.addScene(scene)
      this.scenes.push(scene)
    }

    h2DurationStep = h2StartingDurationStep
    h2Duration = this.$el.clientHeight / 10
    for (const index of Object.keys(this.h2s)) {
      h2Duration += h2DurationStep
      const scene = this.$scrollmagic.scene({
        triggerElement: `#${this.id}`,
        triggerHook: 0,
        duration: 150,
        offset: 0
      })
        .setTween(`#${this.id} #${this.id}-h2-${index}`, { css: { opacity: 0 } })
        // .addIndicators({ name: `#${this.id} - Scene ${index + 1} (duration: ${h2Duration})` })

      this.$scrollmagic.addScene(scene)
      this.scenes.push(scene)
    }

    pOffset = (-pStartingOffset + h2Duration)
    for (const index of Object.keys(this.ps)) {
      pOffset += 500
      // console.log(`#${this.id} ${this.id}-p-${index} - ${pOffset}`)
      const scene = this.$scrollmagic.scene({
        triggerElement: `#${this.id}`,
        triggerHook: 0.3,
        offset: pOffset,
        duration: pDuration
      })
        .setTween(`#${this.id} #${this.id}-p-${index}`, { css: { opacity: 0 } })
        // .addIndicators({ name: `#${this.id} - Scene ${index + 1} (duration: ${pDuration})` })

      this.$scrollmagic.addScene(scene)
      this.scenes.push(scene)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

.main.has-text-black.has-text-left {
    background: #fff !important;
    padding-bottom: 40px;
}

.body {
  z-index: 1;
  display: flex;
  width: 100vw;
  background-color: #fff !important;
  padding-top: 10px !important;

  @include mobile {
    width: 100%;
  }

  .main {
    margin: 0px;
    width: auto;
    z-index: 1;
    padding-top: 10px !important;
    //text-shadow: 0px 0px 30px black;

    p {
      padding-bottom: 2%;

      @media (max-height: 582px) {
        br.first, br.third {
          display: none;
        }
      }
    }

    h2 {
      line-height: 100%;

      span {
        padding-top: 10px !important;

        @include device(iphone-landscape)
        {
          opacity: 1;
          left: 0px;
        }
        @include device(iphone-portrait)
        {
          opacity: 1;
          left: 0px;
        }
        @include device(ipad-landscape)
        {
          opacity: 1;
          left: 0px;
        }
        @include device(ipad-portrait)
        {
          opacity: 1;
          left: 0px;
        }

        position: relative;
        display: block;
      }

      @media (max-height: 582px) {
        br.first, br.third {
          display: none;
        }
      }
    }
    h3 {
      line-height: 100%;
      padding-bottom: 2%;

      span {
        padding-top: 10px !important;

        @include device(iphone-landscape)
        {
          opacity: 1;
          left: 0px;
        }
        @include device(iphone-portrait)
        {
          opacity: 1;
          left: 0px;
        }

        position: relative;
        display: block;
      }

      @media (max-height: 582px) {
        br.first, br.third {
          display: none;
        }
      }
    }
  }
}
</style>
