var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"footer",attrs:{"id":"Footer"}},[_c('div',{staticClass:"mainfooter has-text-left"},[_c('div',{ref:"columns",staticClass:"columns is-multiline main-content-footer"},[_vm._m(0),_c('div',{staticClass:"column col2 is-2-desktop is-full-tablet has-text-left-desktop"},[_c('div',{staticClass:"row-1 has-text-black"},[_c('h3',{staticClass:"bolder"},[_vm._v("Esplora")]),_c('router-link',{staticClass:"footer-link",attrs:{"to":"/mobile-app"}},[_vm._v("Applicazioni Mobile")]),_c('router-link',{staticClass:"footer-link",attrs:{"to":"/digitalization"}},[_vm._v("Digitalizzazione")])],1),_vm._m(1)]),_c('div',{staticClass:"column col3 is-2-desktop is-full-tablet has-text-left-desktop has-text-black"},[_c('h3',{staticClass:"bolder"},[_vm._v("Contatti")]),_c('div',{staticClass:"position"},[_c('router-link',{staticClass:"footer-link",attrs:{"to":"/"}},[_vm._v("Smart Squad Srl")]),_vm._m(2),_vm._m(3)],1),_c('a',{staticClass:"footer-link",attrs:{"href":"tel:04321638681"}},[_vm._v("+39 0432 1638681")]),_c('a',{staticClass:"footer-link",attrs:{"href":"mailto:team@smartsquad.io"}},[_vm._v("team@smartsquad.io")])]),_vm._m(4),_vm._m(5),_c('div',{staticClass:"row has-text-left copyright has-text-black"},[_c('div',{ref:"columns",staticClass:"columns is-multiline final-row"},[_vm._m(6),_c('div',{staticClass:"column is-2-desktop is-full-tablet is-full-mobile por"})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column col1 is-4-desktop is-full-tablet has-text-left-desktop is-hidden-mobile hidden-balloon"},[_c('img',{staticClass:"blimp",attrs:{"src":require("../../assets/images/BigBlimp.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row-2 mobile-padding"},[_c('h3',{staticClass:"bolder has-text-black"},[_vm._v("Assistenza")]),_c('a',{staticClass:"footer-link",attrs:{"href":"https://support.smartsquad.io"}},[_vm._v("Accedi")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"positions"},[_c('h2',{staticClass:"footer-link-subtitle"},[_vm._v("Sede legale")]),_c('a',{staticClass:"footer-link"},[_vm._v("Via Villalta, 38")]),_c('a',{staticClass:"footer-link"},[_vm._v("Udine")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"positions"},[_c('h2',{staticClass:"footer-link-subtitle"},[_vm._v("Sede operativa")]),_c('a',{staticClass:"footer-link"},[_vm._v("Via Napoleonica, 1")]),_c('a',{staticClass:"footer-link"},[_vm._v("Castions di Strada")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column col4 is-2-desktop is-full-tablet has-text-left-desktop has-text-black"},[_c('h3',{staticClass:"bolder has-text-black"},[_vm._v("Prodotti")]),_c('a',{staticClass:"footer-link",attrs:{"href":"https://inksquad.com/","target":"_blank"}},[_vm._v("Inksquad")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column col5 is-2-desktop is-full-tablet has-text-left-desktop has-text-black"},[_c('h3',{staticClass:"bolder"},[_vm._v("Note legali")]),_c('a',{staticClass:"footer-link",attrs:{"href":"https://www.iubenda.com/privacy-policy/86449130"}},[_vm._v("Terms & Conditions")]),_c('a',{staticClass:"footer-link",attrs:{"href":"https://www.iubenda.com/privacy-policy/86449130/cookie-policy"}},[_vm._v("Cookie Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column is-10-desktop is-full-tablet is-full mobile"},[_c('b',[_vm._v("© Smart Squad Srl » ")]),_vm._v("P.IVA IT02898170309")])
}]

export { render, staticRenderFns }