<template lang="pug">
  section#step2.body
    .main.has-text-left.has-text-black.boxed
      //- h2.bigger.has-text-black.is-uppercase
      //-   span#step2-h2-1.is-uppercase Cosa
      //-   br
      //-   span#step2-h2-2.is-uppercase Sviluppiamo
      .columns.step2.is-multiline
        .column.is-4.is-full-tablet.col1
          h3.has-text-black.has-text-left
            | System integration e IoT
          p
            | <b>Progettazione di sistemi software</b> interconnessi con l’obiettivo di semplificarne la gestione, <b>centralizzando</b> dati e funzionalità. Integrazione di dispositivi IoT per massimizzare utilità ed efficienza.
        .column.is-4.is-full-tablet.col2
          h3.has-text-black.has-text-left
            | Data mobilization
          p
            | <b>Accesso ai dati in sicurezza, sempre e ovunque</b> per garantire processi decisionali più snelli, più veloci ed un accesso più rapido alle informazioni.
        .column.is-4.is-full-tablet.col3
          h3.has-text-black.has-text-left
            | API
          p
            | Realizzazione di <b>layer API per il dialogo sicuro tra piattaforme di natura diversa</b> e sistemi custom/terzi o estensioni di sistemi esistenti per potenziarne funzionalità e disponibilità garantendo totale sicurezza, scalabilità e flessibilità.
</template>

<script>
import { isMobile } from '@/utils/isMobile'

export default {

  name: 'Step2',

  mounted () {
    const step2scene1 = this.$scrollmagic.scene({
      triggerElement: isMobile() ? '' : '#step2',
      triggerHook: 0.8,
      duration: 300
    })
      .setTween('#step2 .col1', { css: { bottom: 0 } })
      // .addIndicators({ name: 'Step 4 - Scene 1 (duration: 300)' })

    const step2scene2 = this.$scrollmagic.scene({
      triggerElement: isMobile() ? '' : '#step2',
      triggerHook: 0.8,
      offset: 100,
      duration: 200
    })
      .setTween('#step2 .col2', { css: { bottom: 0 } })
      // .addIndicators({ name: 'Step 4 - Scene 2 (duration: 200)' })

    const step2scene3 = this.$scrollmagic.scene({
      triggerElement: isMobile() ? '' : '#step2',
      triggerHook: 0.8,
      offset: 200,
      duration: 100
    })
      .setTween('#step2 .col3', { css: { bottom: 0 } })
      // .addIndicators({ name: 'Step 4 - Scene 3 (duration: 100)' })

    const step2scene4 = this.$scrollmagic.scene({
      triggerElement: isMobile() ? '' : '#step2',
      triggerHook: 0.6,
      duration: 300
    })
      .setTween('#step2-h2-1', { css: { left: 0 } })
      // .addIndicators({ name: 'Step 3 - Scene 1 (duration: 300)' })

    const step2scene5 = this.$scrollmagic.scene({
      triggerElement: isMobile() ? '' : '#step2',
      triggerHook: 0.6,
      duration: 300,
      offset: 150
    })
      .setTween('#step2-h2-2', { css: { left: 0 } })
      // .addIndicators({ name: 'Step 3 - Scene 1 (duration: 300)' })

    const step2scene6 = this.$scrollmagic.scene({
      triggerElement: '#step2',
      triggerHook: 0,
      duration: 300
    })
      .setTween('#step2-h2-1', { css: { opacity: 0 } })
      // .addIndicators({ name: 'Step 4 - Scene 1 (duration: 300)' })

    const step2scene7 = this.$scrollmagic.scene({
      triggerElement: '#step2',
      triggerHook: 0,
      duration: 300,
      offset: 150
    })
      .setTween('#step2-h2-2', { css: { opacity: 0 } })
      // .addIndicators({ name: 'Step 4 - Scene 1 (duration: 300)' })

    const step2scene8 = this.$scrollmagic.scene({
      triggerElement: '#step2',
      triggerHook: 0,
      duration: 300,
      offset: 800
    })
      .setTween('#step2 .column', { css: { opacity: 0 } })
      // .addIndicators({ name: 'Step 4 - Scene 1 (duration: 300)' })

    this.$scrollmagic.addScene(step2scene1)
    this.$scrollmagic.addScene(step2scene2)
    this.$scrollmagic.addScene(step2scene3)
    this.$scrollmagic.addScene(step2scene4)
    this.$scrollmagic.addScene(step2scene5)
    this.$scrollmagic.addScene(step2scene6)
    this.$scrollmagic.addScene(step2scene7)
    this.$scrollmagic.addScene(step2scene8)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

#step2-h2-1, #step2-h2-2 {
  left: 100vw;
  position: relative;
}

.col1,
.col2,
.col3 {
  bottom: -100vh;
    position: relative;
}

.column {
   @include device(ipad-portrait) {
  width: 100% !important;
}
 @include device(ipad-landscape) {
  width: 100% !important;
}}

#step2 {
  z-index: 1;
  display: flex;
  min-height: 60vh;
  width: 100vw;
  background-color: #fff;
  margin-bottom: 40px;

  p, .column {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .main {
    margin: auto;
    width: auto;
    z-index: 1;
 //   text-shadow: 0px 0px 30px black;

    a {
      padding-top: 30px;
      display: block;
    }

    h2 {
      line-height: 100%;

      @media (max-height: 582px) {
        br.first, br.third {
          display: none;
        }
      }
    }

    h3 {
      padding-bottom: 0px;

      @include tablet { padding-top: 88px; }
      @include mobile {
        font-size: 2.1rem;
        padding-top: 45px;
      } // = 35px
      @include desktop { font-size: 3.2rem; } // = 52px
      @include fullhd { font-size: 3.2rem; } // = 80px
      @include widescreen { font-size: 3.2rem; } // = 80px
    }

    p {
      padding-top: 7% !important;

      @media (max-height: 582px) {
        br.first, br.third {
          display: none;
        }
      }
    }
  }
}
</style>
