<template lang="pug">
#form
  form.vue-form(ref="form" @submit.prevent="sendEmail")
      p Vuoi parlarci del tuo prossimo progetto?
      .columns.form-inputs
        .column
          label.label(for='name') Nome*
          input#name(type="text"
            v-model="name"
            name="name"
            required)
        .column
          label.label(for='name') Cognome*
          input#surname(type="text"
            v-model="surname"
            required
            name="surname")
      .columns
        .column
          label.label(for='name') Azienda*
          input#company(type="text"
            v-model="company"
            required
            name="company")
        .column
          label.label(for='email') Email*
          input#email(type='email' name="email"  v-model="email" required)
      .columns
        .column
          label.label(for='message') Messaggio*
          textarea#textarea.message(name='message' required v-model="message")
      .columns
        .column
          ul.vue-form-list
            li.flex
              input(type='checkbox' name="checkbox" @click=" checked = !checked" required)
              label(for='checkbox') Accetto&nbsp
                |
                a(href="https://www.iubenda.com/privacy-policy/86449130") privacy policy&nbsp
                |
                span e&nbsp
                |
                a(href="https://www.iubenda.com/privacy-policy/86449130/cookie-policy") cookie policy
      div.flex
        input(type='submit' value='Invia' class="checked")
        .confermation(v-if="isSendEmail")
          p.confermation-text Abbiamo ricevuto il tuo messaggio, ti contatteremo quanto prima. Grazie

</template>

<script>
import emailjs from 'emailjs-com'

export default {
  name: 'ContactForm',
  data () {
    return {
      name: '',
      surname: '',
      company: '',
      email: '',
      message: '',
      features: ['Accetto i termini e le condizioni di Privacy policy e Cookie Policy'],
      isSendEmail: false,
      checked: true
    }
  },

  methods: {
    sendEmail () {
      emailjs.sendForm('service_sdn6uz4', 'template_1q21qg4', this.$refs.form, 'user_YUAS7e13BaBup1jL73EAS')
        .then((result) => {
          console.log('SUCCESS!', result.text)
          this.name = ''
          this.surname = ''
          this.company = ''
          this.email = ''
          this.message = ''
          this.isSendEmail = !this.isSendEmail
        }, (error) => {
          console.log('FAILED...', error.text)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

#form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
  height: auto;
}

a{
  font-size: 16px;
  font-weight: $weight-bold !important;
  color: #000;
      @include mobile {
    font-size: 10px !important;
  }
        @include tablet {
    font-size: 16px !important;
  }

  span{
    font-weight: $weight-normal !important;
  }
}

.disabled{
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.3;
}

.column {
  @include mobile {
    padding: 12px 0px !important
  }
}

.confermation{
  height: 4vh;
  width: 90%;
}

.confermation-text{
  font-size: 12px;
  height: inherit;
  display: unset;
  height: inherit;
  color: #3FB498 !important;
  position: relative;
  top: 7px;
  left: -5px;
}

.form-inputs{
  margin-top: 40px !important;
}

.vue-form {
  margin: 15px auto;
  z-index: 30 !important;
  padding: 15px 30px;

  @include mobile {

  padding: 15px 30px;
  padding-right: 40px;
  }

  p{
    color: #000;
    font-weight: 900;
  }
}
.vue-form ul {
  padding: 0px;
}
.vue-form fieldset {
  margin: 24px 0 0 0;
}
.vue-form legend {
  padding-bottom: 10px;
  border-bottom: 1px solid #ecf0f1;
}
.vue-form div {
  position: relative;
  margin: 0;
}
.vue-form .label {
  display: block;
  text-align: left;
  font-weight: 600;
}
.vue-form input,
.vue-form textarea,
.vue-form label {
  color: #000;
}
.vue-form input[type="text"],
.vue-form input[type="email"],
.vue-form textarea,
.vue-form legend {
  display: block;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.vue-form input[type="text"],
.vue-form input[type="email"],
.vue-form textarea {
  padding: 12px;
  border: 1px solid #cfd9db;
  background-color: #ffffff;
  border-radius: 0.25em;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.vue-form input[type="text"]:focus,
.vue-form input[type="email"]:focus,
.vue-form textarea:focus {
  outline: none;
  -webkit-box-shadow: 0 0 5px #cecece;
  box-shadow: 0 0 5px #cecece;
}
.vue-form .select {
  position: relative;
}

.vue-form .vue-form-list {
  margin-top: 0px;
}
.vue-form .vue-form-list::after {
  clear: both;
  content: "";
  display: table;
}
.vue-form .vue-form-list li {
  display: flex;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  float: left;

    @include mobile {
    font-size: 10px !important;
  }
}
.vue-form input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  padding: 0;
  opacity: 0;
  z-index: 2;
}
.vue-form input[type="radio"] + label,
.vue-form input[type="checkbox"] + label {
  padding-left: 24px;
      @include mobile {
  padding-left: 22px !important;
  }
}
.vue-form input[type="radio"] + label::before,
.vue-form input[type="radio"] + label::after,
.vue-form input[type="checkbox"] + label::before,
.vue-form input[type="checkbox"] + label::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -8px;
  width: 16px;
  height: 16px;
}
.vue-form input[type="checkbox"] + label::before {
  border: 1px solid #cfd9db;
  background: #ffffff;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
}
.vue-form input[type="checkbox"] + label::before,
.vue-form input[type="checkbox"] + label::after {
  border-radius: 0.25em;
}
.vue-form input[type="checkbox"] + label::after {
  background-color: #000;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-box-shadow: 0 0 5px #969696;
  box-shadow: 0 0 5px #969696;
  display: none;
}
.vue-form input[type="radio"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Ccircle%20fill%3D%22%23FFFFFF%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%223%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.vue-form input[type="checkbox"] + label::after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2018.1.1%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2216px%22%20height%3D%2216px%22%20viewBox%3D%220%200%2016%2016%22%20enable-background%3D%22new%200%200%2016%2016%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cpolyline%20fill%3D%22none%22%20stroke%3D%22%23FFFFFF%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22square%22%20stroke-miterlimit%3D%2210%22%20points%3D%225%2C8%207%2C10%2011%2C6%20%22%2F%3E%0D%0A%3C%2Fsvg%3E");
}
.vue-form input[type="radio"]:focus + label::before,
.vue-form input[type="checkbox"]:focus + label::before {
  -webkit-box-shadow: 0 0 5px #969696;
  box-shadow: 0 0 5px #969696;
}
.vue-form input[type="radio"]:checked + label::after,
.vue-form input[type="checkbox"]:checked + label::after {
  display: block;
}
.vue-form input[type="radio"]:checked + label::before,
.vue-form input[type="radio"]:checked + label::after,
.vue-form input[type="checkbox"]:checked + label::before,
.vue-form input[type="checkbox"]:checked + label::after {
  -webkit-animation: cd-bounce 0.3s;
  animation: cd-bounce 0.3s;
}
.vue-form textarea {
  min-height: 120px;
  resize: vertical;
  overflow: auto;
}
.vue-form input[type="submit"] {
  @include mobile {
  font-size: 16px  !important;}

  max-width: fit-content;
  font-weight: $weight-bolder !important;
  background: rgba(255,255,255,1);
  border: solid 3px #000;
  border-radius: 40px;
  padding: 15px 30px;
  color: rgba(0,0,0,1);
  float: left;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: 12px;

}
.no-touch .vue-form input[type="submit"]:hover {
  background: #969696;
}
.vue-form input[type="submit"]:focus {
  outline: none;
  background: #969696;
}
.vue-form input[type="submit"]:active {
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}

@-webkit-keyframes cd-bounce {
  0%,
  100% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
  }
}
@keyframes cd-bounce {
  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}
</style>
