<template lang="pug">
svg(
  xmlns='http://www.w3.org/2000/svg'
  width='28.994'
  height='28.994'
  viewbox='0 0 28.994 28.994'
  @mouseover="onOver($event)"
  @mouseout="onOut($event)"
)
  g(transform='translate(2.139 2.123)')
    line#line1(
      data-name='Line 1'
      x2='34.977'
      y2='0.023'
      transform='rotate(45)'
      fill='none'
      stroke='#fff'
      stroke-linecap='round'
      stroke-width='3'
    )
    line#line2(
      data-name='Line 2'
      y1='0.023'
      x2='34.977'
      transform='translate(24.732 0.017) rotate(135)'
      fill='none'
      stroke='#fff'
      stroke-linecap='round'
      stroke-width='3'
    )
</template>

<script>
export default {
  methods: {
    onOver: function (event) {
      // console.log(event)
    },
    onOut: function (event) {
      // console.log(event)
    }
  }
}
</script>
