<template lang="pug">
  section.body
    .rows.boxed
      .row.point
        .columns
          .column.is-three-thirds-desktop
            .element.first-element
              h3.has-text-black.has-text-left 1. Definizione obiettivo
              p.has-text-black.has-text-left Questa è la fase fondamentale che guiderà le scelte successive e lo sviluppo dell’intero progetto. Definirà le risorse da mettere in campo e le tecnologie da utilizzare, ottimizzando costi e tempistiche.
          .column.is-one-third-desktop.hide-on-mobile.hide-on-tablet
            img.navigation-tool(src="../../assets/images/ProcessIllustration1.svg")
      .row.point
        .columns
          .column.is-one-third-desktop.hide-on-mobile.hide-on-tablet
            img.formula(src="../../assets/images/ProcessIllustration2.svg")
          .column.is-three-thirds-desktop
            .element.second-element
              h3.has-text-black.has-text-left 2. Analisi, progettazione e design
              p.has-text-black.has-text-left 5 giorni di analisi, organizzazione e progettazione finalizzati a creare un primo prototipo grafico e logico di MVP. L’MVP (Minimum Viable Product) è la versione più semplice e funzionante del prodotto che si vuole lanciare.
      .row.point
        .columns
          .column.is-three-thirds-desktop
            .element.third-element
              h3.has-text-black.has-text-left 3. Realizzazione e lancio
              p.has-text-black.has-text-left Sviluppo e realizzazione del primo MVP. Definizione della roadmap e delle tecnologie più adatte, seguendo il prototipo grafico validato al precedente stadio. Successivamente, i 3 stadi verranno riproposti per ogni test o definizione/validazione delle nuove funzionalità.
          .column.is-one-third-desktop.hide-on-mobile.hide-on-tablet
            img.plane(src="../../assets/images/ProcessIllustration3.svg")
</template>

<script>
export default {
  name: 'ColumnsSection',

  mounted () {
    const firstlement = this.$scrollmagic
      .scene({
        triggerHook: 1,
        duration: 200,
        offset: 100
      })
      .setTween('.first-element', { css: { right: 0 } })
    const secondelement = this.$scrollmagic
      .scene({
        triggerHook: 1,
        duration: 400,
        offset: 500
      })
      .setTween('.second-element', { css: { left: 0 } })
    const thirdelement = this.$scrollmagic
      .scene({
        triggerHook: 0,
        duration: 600,
        offset: 600
      })
      .setTween('.third-element', { css: { right: 0 } })

    const firstillustration = this.$scrollmagic
      .scene({
        triggerHook: 1,
        duration: 200,
        offset: 120
      })
      .setTween('.navigation-tool', { css: { left: -50, opacity: 0.3 } })

    const secondillustration = this.$scrollmagic
      .scene({
        triggerHook: 1,
        duration: 400,
        offset: 520
      })
      .setTween('.formula', { css: { right: -50, opacity: 0.3 } })

    const thirdillustration = this.$scrollmagic
      .scene({
        triggerHook: 0,
        duration: 600,
        offset: 620
      })
      .setTween('.plane', { css: { left: -50, opacity: 0.3 } })

    this.$scrollmagic.addScene(firstlement)
    this.$scrollmagic.addScene(secondelement)
    this.$scrollmagic.addScene(thirdelement)
    this.$scrollmagic.addScene(firstillustration)
    this.$scrollmagic.addScene(secondillustration)
    this.$scrollmagic.addScene(thirdillustration)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

h3{
padding-bottom: 2%
}

.body {
  z-index: 1;
  display: flex;
  height: auto;
  width: 100vw;
  background-color: transparent;
      padding-top: 100px;
}

.column{
      margin: auto;
    width: auto;
    z-index: 1;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.point{
  margin-bottom: 70px;
}

.element{
  padding-bottom: 2rem;
}

.first-element, .third-element{
  z-index: 1 !important;
  position: relative;
  right: 100vw;

  @include device(iphone-portrait) {
  right: 0vw;
  }
  @include device(iphone-landscape) {
  right: 0vw;
  }
}

.second-element{
  z-index: 1 !important;
  position: relative;
  left: 100vw;

  @include device(iphone-portrait) {
  left: 0vw !important;
  }
  @include device(iphone-landscape) {
  left: 0vw !important;
  }
}
img.navigation-tool {
  z-index: 1 !important;
  height: 300px;
  position: relative;
  left: 100vh;
  opacity: 1;
 transition: 0.3s cubic-bezier(.47,1.64,.41,.8);

   @include device(iphone-portrait) {
  left: 0;
  opacity: 1;
  }
  @include device(iphone-landscape) {
  left: 0;
  opacity: 1;
  }
}
img.plane {
  z-index: 1 !important;
  height: 250px;
  position: relative;
  left: 100vh;
  opacity: 0;
 transition: 0.3s cubic-bezier(.47,1.64,.41,.8);

    @include device(iphone-portrait) {
  left: 0;
  opacity: 1;
  }
  @include device(iphone-landscape) {
  left: 0;
  opacity: 1;
  }
}

img.formula {
  z-index: 1 !important;
  height: 300px;
  position: relative;
  right: 100vh;
  opacity: 0;
 transition: 0.3s cubic-bezier(.47,1.64,.41,.8);

    @include device(iphone-portrait) {
  right: 0;
  opacity: 1;
  }
  @include device(iphone-landscape) {
  right: 0;
  opacity: 1;
  }
}

.hide-on-mobile{

      @include device(iphone-portrait) {
display: none;
  }
  @include device(iphone-landscape) {
display: none;
  }
}

.hide-on-tablet{

      @include device(ipad-portrait) {
display: none;
  }
}

</style>
