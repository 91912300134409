<template lang="pug">
  object3D(name="Stars")
</template>

<script>
import { Vector3, Geometry, TextureLoader, PointsMaterial, Points } from 'three'
import { Object3D } from 'vue-threejs'

export default {
  name: 'Star',

  mixins: [Object3D],

  props: {
    scale: {
      type: Object,
      default: undefined
    },
    textureColor: {
      type: Number,
      default: undefined
    },
    textureUrl: {
      type: String,
      default: ''
    },
    textureSize: {
      type: Number,
      default: 1
    },
    quantity: {
      type: Number,
      default: 3000
    }
  },

  watch: {
    color: {
      handler () {
        this.curObj.children[0].material.color.set(this.color)
      }
    },
    scale: {
      handler () {
        if (this.scale) {
          this.curObj.children[0].material.map.repeat.set(
            this.scale.x,
            this.scale.y
          )
          // console.log('scale')
        }
      }
    }
  },

  mounted () {
    // console.log('mounted')

    const starGeo = new Geometry()
    for (let i = 0; i < this.quantity; i++) {
      const star = new Vector3(
        Math.random() * 900 - 300,
        Math.random() * 900 - 300,
        Math.random() * 900 - 300
      )
      starGeo.vertices.push(star)
    }

    const starMaterial = new PointsMaterial({
      color: this.textureColor,
      size: this.textureSize,
      map: new TextureLoader().load(this.textureUrl),
      transparent: true
    })

    const stars = new Points(starGeo, starMaterial)
    if (this.curObj) {
      this.curObj.parent.add(stars)
    }
  },

  methods: {
    getVertices () {
      return [
        new Vector3(
          Math.random() * 600 - 300,
          Math.random() * 600 - 300,
          Math.random() * 600 - 300
        )
      ]
    }
  }
}
</script>
