<template lang="pug">
#home
  .home-content
    #white
    StarsCanvas(
      :id="starsCanvasId",
      :animation="animation",
      :animationSpeed="animationSpeed"
    )
    ScrollIcon#scroll-icon.has-text-centered.hide-on-mobile-landscape
    Header1Section#section1(
      h1="Vuoi digitalizzare <br class=\"hide-on-mobile-landscape\">la tua azienda?"
    )
    img.airship(src="../assets/images/airship.svg")
    ColumnsSection#section2.hide-on-mobile-landscape

    //- NoOctaContentSection#section3.hide-on-mobile-landscape.hide-on-tablet(
    //-   :h2s="['Guidiamo la tua azienda nella trasformazione digitale, qualsiasi sia il tuo settore']",
    //-   :ps="['La nostra parola chiave è <b>usabilità</b>, <b>eccelliamo nella progettazione delle interfacce grafiche e delle architetture</b>. Creiamo <b>applicazioni multi piattaforma native ed altamente performanti.<b>']"
    //- )
    Header2BiggerSection#section4.hide-on-mobile-landscape(
      :h2s="['User eXperience design']",
      :h3s="['Progettiamo esperienze di valore']",
      :ps="['Realizziamo prodotti digitali che si contraddistinguono per <b>elevata usabilità. Un&#8217attenta progettazione di UX e UI</b> si traduce in un’app visivamente accattivante, risultato di un’accurata analisi e ricerca, di una <b>User eXperience</b> e di una <b>User Interface efficienti.</b> Il prodotto finale assicura un’interfaccia utente di facile interazione, divertente ed efficace, in grado di offrire la miglior esperienza possibile.']"
    )
    img.scheme.hide-on-mobile-landscape(src="../assets/images/scheme.svg")
    Step4.hide-on-mobile-landscape
    #form.gif.hide-on-mobile-landscape(ref="form")
      img.bottle(:src="bottle")
    ContactForm.hide-on-mobile-landscape
  Footer.hide-on-mobile-landscape
</template>

<script>
import gif from '../assets/images/BOTTLES.gif'
import StarsCanvas from '@/components/3d/StarsCanvas.vue'
import ContactForm from '@/components/shared/ContactForm.vue'
import Header1Section from '@/components/home/Header1Section.vue'
import ColumnsSection from '@/components/home/ColumnsSection.vue'
import NoOctaContentSection from '@/components/shared/NoOctaContentSection.vue'
import Header2BiggerSection from '@/components/shared/Header2BiggerSection.vue'
import Step4 from '@/components/home/Step4.vue'
import Footer from '@/components/shared/Footer.vue'
import ScrollIcon from '@/components/ScrollIcon.vue'

export default {
  name: 'Home',

  components: {
    // Home components
    StarsCanvas,
    Header1Section,
    ColumnsSection,
    NoOctaContentSection,
    Header2BiggerSection,
    Step4,
    Footer,
    // Global components
    ScrollIcon,
    ContactForm
  },

  data () {
    return {
      animation: true,
      animationSpeed: 1,
      starsCanvasId: 'home-stars-canvas',
      bottle: gif
    }
  },

  mounted () {
    const localThis = this

    const homeScene1 = this.$scrollmagic
      .scene({
        triggerElement: '#home',
        triggerHook: 0,
        offset: -1,
        duration: this.$el.clientHeight / 3
      })
      .on('enter leave', function (e) {
        localThis.animation = e.type === 'enter'
      })
      .on('progress', function (e) {
        localThis.animationSpeed = 1 - e.progress
      })
    // .addIndicators({ name: `Home - Scene 1 (duration: ${this.$el.clientHeight / 3})` })

    const homeScene2 = this.$scrollmagic
      .scene({
        triggerHook: 0.5,
        duration: 300,
        offset: 150
      })
      .setTween('#scroll-icon', { css: { opacity: 0 } })
    /*
    const homeScene3 = this.$scrollmagic
      .scene({
        triggerHook: 0.5,
        duration: 300,
        offset: 150
      })
      .setTween(`#${this.starsCanvasId}`, { css: { opacity: 0.5 } })
    // .addIndicators({ name: 'Step 0 (duration: 300)' })
*/
    const whitebackground = this.$scrollmagic
      .scene({
        triggerHook: 0.8,
        duration: 500,
        offset: 250
      })
      .setTween('#white', { css: { opacity: 1 } })
    // .addIndicators({ name: 'Step 0 (duration: 300)' })

    const airshipMovment = this.$scrollmagic
      .scene({
        triggerHook: 1,
        duration: 500,
        offset: 600
      })
      .setTween('.airship', { css: { right: -430, top: 730 } })
    // .addIndicators({ name: 'Step 0 (duration: 300)' })
    const airshipOpacity = this.$scrollmagic
      .scene({
        triggerHook: 1,
        duration: 500,
        offset: 700
      })
      .setTween('.airship', { css: { opacity: 0.3 } })
    const schemeMovment = this.$scrollmagic
      .scene({
        triggerElement: '#section2',
        triggerHook: 0,
        duration: 500,
        offset: 500
      })
      .setTween('.scheme', { css: { top: 0 } })
    // .addIndicators({ name: 'Step 0 (duration: 300)' })
    const schemeOpacity = this.$scrollmagic
      .scene({
        triggerElement: '.scheme',
        triggerHook: 1,
        duration: 500,
        offset: 80
      })
      .setTween('.scheme', { css: { opacity: 1 } })
    // .addIndicators({ name: 'Step 0 (duration: 300)' })

    /* const whiteBlockHeight = this.$scrollmagic
      .scene({
        triggerHook: 1,
        duration: 0,
        triggerElement: '.footer',
        offset: -100
      })
      .setTween('#white', { css: { bottom: '800px' } })
    // .addIndicators({ name: 'whiteBlockHeight' })
*/
    // Attaching scrollmagic controller to element
    this.$scrollmagic.addScene(homeScene1)
    this.$scrollmagic.addScene(homeScene2)
    // this.$scrollmagic.addScene(homeScene3)
    this.$scrollmagic.addScene(whitebackground)
    this.$scrollmagic.addScene(airshipMovment)
    this.$scrollmagic.addScene(airshipOpacity)
    this.$scrollmagic.addScene(schemeMovment)
    this.$scrollmagic.addScene(schemeOpacity)
  // this.$scrollmagic.addScene(whiteBlockHeight)
  },

  metaInfo () {
    return {
      seoTitle: 'homepage'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

#home {
  overflow: hidden;

  #section4{
    padding-top: 30px !important;
  }

  .bottle{
    position: relative;
    z-index: 1;
    width: 170px;
    transform: rotate(45deg);
  }

  #white {
    background: #fff;
    z-index: 1;
    opacity: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0
  }

  .resize-observer {
    position: absolute;
  }

  .canvas {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 0;
    top: 0px;
    opacity: 1;
    background: #000;
  }

  #scroll-icon {
    z-index: 2;
    position: fixed;
    bottom: 20px;
    display: inline-flex;
    // margin-left: -20px;
    left: calc(50% - 17px);
  }
}

img.airship {
  z-index: 1 !important;
  position: relative;
  width: 600px;
  top: 0px;
  right: 100vw;
  margin-top: -300px;
  opacity: 0.3;

@include mobile {
display: none !important;
  }
@include device(ipad-portrait) {
display: none !important;
  }

}

img.scheme {
  z-index: 1 !important;
  width: 450px;
  opacity: 0;
  position: relative;
  top: 300px;

        @include mobile {
width: 300px;
  top: 0px;
  opacity: 1;
  }
}

@media screen and (max-width: 1024px) {
  img.airship {
    display: none !important;
  }
}

.hide-on-mobile{

      @include device(iphone-portrait) {
display: none;
  }
  @include device(iphone-landscape) {
display: none;
  }
}
</style>
