<template lang="pug">
  .canvas(ref="canvas",:id="id")
    resize-observer(@notify="resize")
    renderer#renderer(:size="{w: 1, h: 1}", :obj="globalRenderer", ref="renderer")
      scene(ref="scene")
        Stars(
          :texture-color="colorAsNumber(this.stars.color)",
          :texture-size="this.stars.size",
          :quantity="6000",
          texture-url="/star.png"
        )
</template>

<script>
import Stars from '@/components/3d/Stars.vue'
import * as OrbitControls from 'orbit-controls-es6'
import { Color, LinearToneMapping, PerspectiveCamera, WebGLRenderer } from 'three'
import { BloomEffect, EffectComposer, RenderPass, EffectPass } from 'postprocessing'
import { ResizeObserver } from 'vue-resize'

export default {
  name: 'StarsCanvas',

  components: {
    Stars,
    ResizeObserver
  },

  props: {
    animation: {
      type: Boolean
    },
    animationSpeed: {
      type: Number,
      default: 1
    },
    id: {
      type: String,
      default: 'stars-canvas'
    }
  },

  data () {
    return {
      lockHeader: false,
      animationIsRunning: false,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 1] // [0.25, 0.75] if you want a 25% offset!
      }, // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      globalRenderer: null,
      globalCamera: null,
      globalOrbitControls: null,
      globalComposer: null,
      sceneColor: {
        hex: '000000'
      },
      stars: {
        color: {
          hex: '#FFFFFF'
        },
        size: 1
      },
      height: '1300px',
      minHeight: '150px'
    }
  },

  watch: {
    animation: function (value) {
      if (value) {
        this.startAnimation()
      } else {
        this.stopAnimation()
      }
    },
    animationSpeed: function (value) {
      if (value && this.globalOrbitControls && this.globalOrbitControls.autoRotateSpeed) {
        this.globalOrbitControls.autoRotateSpeed = value
      }
    }
  },

  created () {
    this.init()
  },

  mounted () {
    this.resize()

    // Avoid to let the vue-threejs library render operations
    this.$refs.renderer.animate = () => {}
  },

  methods: {
    colorAsNumber (value) { return (new Color(value.hex)).getHex() },

    init () {
      this.globalRenderer = new WebGLRenderer({ antialias: true, alpha: true })
      this.globalRenderer.toneMapping = LinearToneMapping
      this.globalRenderer.setClearColor(0x000000, 0)
      this.globalCamera = new PerspectiveCamera(45, 1 / 1, 1, 500)
      this.globalCamera.position.set(30, 0, 100)

      this.globalOrbitControls = new OrbitControls(this.globalCamera, this.globalRenderer.domElement)
      this.globalOrbitControls.enablePan = false
      this.globalOrbitControls.enableZoom = false
      this.globalOrbitControls.enableRotate = false
      this.globalOrbitControls.position = { x: 3, y: 3, z: 3 }

      if (this.animation) {
        this.startAnimation()
      }
    },

    resize () {
      this.globalCamera.aspect = this.$refs.canvas.clientWidth / this.$refs.canvas.clientHeight
      this.globalRenderer.setSize(this.$refs.canvas.clientWidth, this.$refs.canvas.clientHeight)
      this.globalCamera.updateProjectionMatrix()
    },

    scaleValue (w, h, d) {
      return {
        x: w,
        y: h,
        z: d
      }
    },

    startAnimation () {
      if (this.animationIsRunning || !this.globalOrbitControls) { return }
      this.animationIsRunning = true
      this.globalOrbitControls.autoRotateSpeed = this.animationSpeed
      this.globalOrbitControls.autoRotate = true

      this.animate()
    },

    stopAnimation () {
      if (!this.globalOrbitControls) { return }
      this.animationIsRunning = false
      this.globalOrbitControls.autoRotate = false
      this.fps = 0
    },

    animate () {
      if (!this.animationIsRunning) return
      requestAnimationFrame(this.animate.bind(this.$refs.canvas))

      this.fps = this.fps + 1

      this.globalOrbitControls.update()
      if (this.$refs.scene) {
        if (this.globalComposer == null) {
          this.globalComposer = new EffectComposer(this.globalRenderer)

          // Set the renderer
          this.globalComposer.addPass(new RenderPass(this.$refs.scene.curObj, this.globalCamera))

          const bloomEffect = new BloomEffect({
            intensity: 3,
            luminanceThreshold: 0.3,
            luminanceSmoothing: 0.368,
            resolutionScale: 1
            // blendFunctions:
          })
          const effectPass = new EffectPass(this.globalCamera, bloomEffect)
          effectPass.renderToScreen = true

          // Set the Bloom Effect
          this.globalComposer.addPass(effectPass)
        }

        // this.globalRenderer.render(this.$refs.scene.curObj, this.globalCamera)
        this.globalComposer.render(this.globalCamera)
      }
    }
  },

  // Vue Idle
  onIdle () {
    if (this.animation) {
      this.stopAnimation()
    }
  },

  onActive () {
    if (this.animation) {
      this.startAnimation()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";
</style>
