<template lang="pug">
section.body(:ref="id",:id="id")
  main.title.has-text-center
    h1.has-text-white(v-html="h1")
    h4.has-text-white(v-html="h3")
</template>

<script>
export default {
  name: 'Header1Section',

  props: {
    id: {
      type: String,
      default: 'example-h2'
    },
    h1: {
      type: String,
      default: 'H1 tag header section.'
    },
    h3: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

.body {
  z-index: 1;
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  padding: 0px 32px;

  .title {
    margin: auto;
    width: auto;
    z-index: 1;
   // text-shadow: 0px 0px 30px black;
  }

  h4{
    font-weight: $weight-normal;
    margin-top: 25px;
  }
}
</style>
