<template lang="pug">
section#step4.body
  .main.has-text-left.has-text-black.boxed
    h2#step4-h2-1.bigger.has-text-black.is-uppercase
    .columns.step4.is-multiline(ref="columns")
      .column.col1
        h3.has-text-black.has-text-left
          | Applicazioni mobile native e ibride
        p.has-text-black
          | Realizziamo <b>app mobile native multipiattaforma iOS e Android.</b> Creiamo solide app che si distinguono per <b>elevata usabilità, flessibilità e scalabilità.</b> Progettiamo e realizziamo app per smartphone e tablet che garantiscono <b>accesso diretto ai dati anche in mobilità e offline.</b> Scegliamo le migliori tecnologie, fornendo soluzioni di <b>semplice utilizzo e all’avanguardia,</b> tailor made.
        router-link(to="/mobile-app").router
          b  Il nostro approccio
          .smaller-underline
      .column.col2
        h3.has-text-black.has-text-left
          | Digitalizzazione e data mobilization
        p.has-text-black
          | La nostra mission è garantire alle aziende <b>disponibilità e accesso ai dati sempre, ovunque ed in sicurezza</b> su tutte le famiglie di dispositivi, grazie a logiche di <b>system integration e di data mobilization.</b><br><br><b>Accrescere il valore dei dati a disposizione, attraverso l’interconnessione dei dispositivi</b> e dei software esistenti, rendendoli centralizzati come in un unico sistema.
        router-link(to="/digitalization").router
          b Digitalizzare un’azienda
          .underline
      //- .column.col3
      //-   h3.has-text-black.has-text-left
      //-     | Digitalizzazione
      //-   p
      //-     | Analizziamo la situazione attuale per <b>identificare i processi ripetitivi e ricorrenti</b> in modo da <b>automatizzarli e semplificarli</b> fornendo maggiore <b>controllo e analisi</b>. L’automazione ed il controllo risultano essere elementi fondamentali per la <b>digital transformation</b>.
      //-   router-link.has-text-black(to="/digitalization")
      //-     b La digitalizzazione
</template>

<script>
import { isMobile } from '@/utils/isMobile'

export default {

  name: 'Step4',

  mounted () {
    const step4scene1 = this.$scrollmagic.scene({
      triggerElement: isMobile() ? '' : '#step4',
      triggerHook: 1,
      duration: 300
    })
      .setTween(
        '#step4 .col1',
        { css: this.$devices.isIphone() ? { opacity: 1 } : { bottom: 0 } }
      )
      // .addIndicators({ name: 'Step 4 - Scene 1 (duration: 300)' })

    const step4scene2 = this.$scrollmagic.scene({
      triggerElement: isMobile() ? '' : '#step4',
      triggerHook: 1,
      offset: 100,
      duration: 200
    })
      .setTween(
        '#step4 .col2',
        { css: this.$devices.isIphone() ? { opacity: 1 } : { bottom: 0 } }
      )
      // .addIndicators({ name: 'Step 4 - Scene 2 (duration: 200)' })

    const step4scene3 = this.$scrollmagic.scene({
      triggerElement: isMobile() ? '' : '#step4',
      triggerHook: 0.8,
      offset: 200,
      duration: 100
    })
      .setTween(
        '#step4 .col3',
        { css: this.$devices.isIphone() ? { opacity: 1 } : { bottom: 0 } }
      )
      // .addIndicators({ name: 'Step 4 - Scene 3 (duration: 100)' })

    this.$scrollmagic.addScene(step4scene1)
    this.$scrollmagic.addScene(step4scene2)
    this.$scrollmagic.addScene(step4scene3)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

.main.has-text-left.has-text-black.boxed {
    background: #fff !important;
        padding-bottom: 40px;
}

.body{
padding-bottom: 40px;
  background: #fff !important;
}

#step4-h2-1 {
  position: relative;
}

.col1,
.col2,
.col3 {
  position: relative;

  @include device(iphone-portrait) {
    bottom: 0px;
  }
  @include device(iphone-landscape) {
    bottom: 0px;
  }
}

#step4 {
  z-index: 1;
  display: flex;
  min-height: 100vh;
  width: 100vw;
  background: #fff !important;

  .column {
    padding-top: 0px !important;
    padding-bottom: 60px !important;

    @include mobile {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }

    @include device(ipad-portrait) {
      width: 100% !important;
    }
    @include device(ipad-landscape) {
      width: 100% !important;
    }
  }

  .main {
    margin: auto;
    width: auto;
    z-index: 1;
  //  text-shadow: 0px 0px 30px black;

    @include mobile {
      width: 100%;
    }

    p { padding: 30px 0px; }

    a {
      display: flex;

      b {
        font-family: "San Francisco Bold";
        font-weight: $weight-bolder;

        @include mobile { font-size: 1.5rem; }
        @include desktop { font-size: 1.5rem; }
        @include fullhd { font-size: 1.5rem; }
        @include widescreen { font-size: 1.5rem; }
      }
    }

    h2 {
      @media (max-height: 582px) {
        br.first, br.third {
          display: none;
        }
      }
    }

    h3 {
      padding-bottom: 0px;

      @include tablet { padding-top: 80px; }
      @include mobile {
        font-size: 2.1rem;
        padding-top: 45px;
      } // = 35px
      @include desktop { font-size: 3.2rem; } // = 52px
      @include fullhd { font-size: 3.2rem; } // = 80px
      @include widescreen { font-size: 3.2rem; } // = 80px
    }

    p {
      padding-top: 7%;

      @media (max-height: 582px) {
        br.first, br.third {
          display: none;
        }
      }
    }
  }
}
.router{
    color: rgba(0,0,0,1);
    cursor: pointer;
    background: rgba(255,255,255,1);
    display: flex;
    padding: 15px 30px;
    max-width: fit-content;
    border: solid 3px #000;
    border-radius: 40px;
  @include mobile {
    font-size: 0.5rem !important;
    white-space: nowrap;
    text-align: center;
      b{
      font-size: 1rem !important;
      }
    }
  b {
    margin: auto;
    line-height: 100%;
  }
}

.router:hover{

      color: rgba(255,255,255,1);
    cursor: pointer;
    background: rgba(0,0,0,1);
    border: solid 3px #000;
    border-radius: 40px;

}
</style>
