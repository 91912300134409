<template lang="pug">
  section.body(:ref="id",:id="id")
    .title.boxed
      h2.smaller.has-text-white(v-html="h2")
</template>

<script>
export default {
  name: 'Header2Section',

  props: {
    id: {
      type: String,
      default: 'example-h2'
    },
    h2: {
      type: String,
      default: 'Example of an H2 tag header section.'
    }
  },

  mounted () {
    const safeThis = this
    const step0scene1Duration = 500
    const step0scene1 = this.$scrollmagic.scene({
      triggerElement: `#${this.id}`,
      triggerHook: 0,
      duration: step0scene1Duration
    })
      .setTween(`#${safeThis.id} h2`, { css: { opacity: 0 } })
      // .addIndicators({ name: `#${safeThis.id} - Scene 1 (duration: ${step0scene1Duration})` })

    this.$scrollmagic.addScene(step0scene1)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

.body {
  z-index: 1;
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: transparent;

   @include device(ipad-portrait) {
    height: 50vh !important;
  }

  .title {
    margin: auto;
    width: auto;
    z-index: 1;
   // text-shadow: 0px 0px 30px black;

    h2 {
      opacity: 1;
      @media (max-height: 582px) {
        br.first, br.third {
          display: none;
        }
      }
    }
  }
}
</style>
