<template lang="pug">
  #responsiveness
    section#step4.body.block
      .main.has-text-centered.has-text-white
        h2#step4-h2-1.bigger.has-text-white.is-uppercase TEST
</template>

<script>

export default {
  name: 'Home',

  components: {
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

#responsiveness {
  background-color: brown;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  section,
  .main {

    width: 100%;
    height: 100%;
  }

  .main {
    display: grid;
  }
}

.block {
  width: 100%;
  height: 100px;
  background-color: black;

  @include device(iphone-landscape) {
    background: green;
  }

  @include device(iphone-portrait) {
    background: red;
  }

  @include device(ipad-landscape) {
    background: yellow;
  }

  @include device(ipad-portrait) {
    background: purple;
  }
}
</style>
