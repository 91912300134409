<template lang="pug">
  section.body
    .columns.boxed
      .column.is-half-desktop.is-full-tablet.column-content
        .element.first-element
          h3.has-text-black.has-text-left Applicazioni mobile
          p.has-text-black.has-text-left iOS e Android ad elevata usabilità, flessibili, scalabili, native o ibride.
        .element.second-element
          h3.has-text-black.has-text-left Piattaforme web
          p.has-text-black.has-text-left Efficaci, semplici e dinamiche per l’accesso alle piattaforme da browser web o desktop.
        .element.third-element
          h3.has-text-black.has-text-left Data mobilization
          p.has-text-black.has-text-left Disponibilità e accesso ai dati sempre, ovunque ed in sicurezza.
        .element.fourth-element
          h3.has-text-black.has-text-left System integration
          p.has-text-black.has-text-left Valorizzare i dati grazie all'interconnessione di sistemi.
      .column.is-half-desktop.hide-on-mobile.hide-on-tablet
</template>

<script>
export default {
  name: 'ColumnsSection',

  mounted () {
    const firstlement = this.$scrollmagic
      .scene({
        triggerHook: 0.8,
        duration: 500,
        offset: 250
      })
      .setTween('.first-element', { css: { right: 0 } })
    const secondelement = this.$scrollmagic
      .scene({
        triggerHook: 0.8,
        duration: 500,
        offset: 350
      })
      .setTween('.second-element', { css: { right: 0 } })
    const thirdelement = this.$scrollmagic
      .scene({
        triggerHook: 0.8,
        duration: 500,
        offset: 450
      })
      .setTween('.third-element', { css: { right: 0 } })
    const fourthelement = this.$scrollmagic
      .scene({
        triggerHook: 0.8,
        duration: 500,
        offset: 550
      })
      .setTween('.fourth-element', { css: { right: 0 } })

    this.$scrollmagic.addScene(firstlement)
    this.$scrollmagic.addScene(secondelement)
    this.$scrollmagic.addScene(thirdelement)
    this.$scrollmagic.addScene(fourthelement)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

.body {
  z-index: 1;
  display: flex;
  height: auto;
  width: 100vw;
  background-color: transparent;
  padding: 60px 0px;
}

h3{
padding-bottom: 2%
}

.column{
      margin: auto;
    width: auto;
    z-index: 1;
}

.columns-content{
    @include device(ipad-landscape) {
  width: 100% !important;
    }
}

.element{
  padding-bottom: 2rem;
}

.first-element, .second-element, .third-element, .fourth-element{
  z-index: 1 !important;
  position: relative;
  right: 100vw;

  @include device(iphone-portrait) {
  right: 0vw !important;
  }
  @include device(iphone-landscape) {
  right: 0vw !important;
  }
    @include device(ipad-portrait) {
  right: 0vw !important;
  }
  @include device(ipad-landscape) {
  right: 0vw !important;
  }
}

.hide-on-mobile{

      @include device(iphone-portrait) {
display: none;
  }
  @include device(iphone-landscape) {
display: none;
  }
}

.hide-on-tablet{

      @include device(ipad-portrait) {
display: none;
  }
}

  .column {
    @include device(ipad-portrait) {
      width: 100% !important;
    }
  }

</style>
