<template lang="pug">
div(name="modal")
  .modal
    .modal__mask
    .modal__wrapper
      .modal__container
        .columns.is-multiline.is-widescreen
          .column.left
            router-link.simple-element(to="/mobile-app")  Applicazioni Mobile
            br
            router-link.simple-element(to="/digitalization")  Digitalizzazione
            br
            a(href="#") Prodotti
            .products
              a(href='https://inksquad.com/' target="_blank").product Inksquad
          .column.right
            .wrapper
              .contacts
                span Udine, Via Villalta, 38
                br
                span Castions di Strada, Via Napoleonica, 1
                br
                a.link(href="tel:04321638681") +39 0432 1638681
                br
                a.link(href="mailto:team@smartsquad.io") team@smartsquad.io
        .footer.has-text-center.has-text-white
          p
            b © Smart Squad Srl&nbsp;
            span.first-divider »&nbsp;
            | P.IVA {{ vatNumber }} »&nbsp;
            a.has-text-white(href="https://www.iubenda.com/privacy-policy/86449130") Privacy&nbsp;
            | »&nbsp;
            a.has-text-white(
              href="https://www.iubenda.com/privacy-policy/86449130/cookie-policy"
            ) Cookie policy
</template>

<script>
export default {
  name: 'Menu',

  data () {
    return {
      vatNumber: 'IT02898170309'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

.modal,
.modal__mask,
.modal__wrapper {
  width: 100vw;
  height: 100vh;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 9999999;

  .router-link-active {
    color: $smart-squad-color !important;
  }

  &__mask {
    background: $black;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__wrapper {
    position: relative;
    display: flex;
  }

  &__container {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .columns {
      width: 100%;
      height: 100%;
      background-color: $black;
      margin: 0px;
      padding: 0px;

      .column {
        padding: 0px;
      }

      .column.left {
        padding-left: 100px;
        text-align: left;
        font-weight: $weight-bolder;
        align-items: center;

        padding-top: 0px;
        padding-bottom: 0px;

        @media screen and (max-height: 568px){
          padding-top: 100px !important;
        }

        // AKA Mobile
        @include mobile {
          padding-left: 20px;
          padding-top: 150px;
          padding-bottom: 20px;
        }

        // AKA Tablet
        @include tablet {
          padding-left: 100px;
          padding-top: 150px;
          padding-bottom: 0px;
        }
        @media screen and (min-width: 1023px){
          padding-top: 0px !important;
        }

        @include device (ipad-landscape) {
          width: 100% !important;
          margin: auto auto
        }

        @include device (ipad-portrait) {
          margin: auto auto
        }

        @include desktop {
          padding-left: 50px;
          padding-top: 0px;
          padding-bottom:  0px;
        }

        a {
          font-weight: $weight-bolder;
          display: contents;
          color: $white !important;
        }

        .product {
          font-weight: 200;
          display: contents;
          color: $white !important;
          font-size: 20px;
          line-height: 1.5;
        }
      }

      .products {
        padding-left: 20px;
        border-left: 2px solid #fff
      }

      .inverted-simple-product {
        margin-top: 15px !important;
        a {
          font-size: 1.86rem;
        }
      }

      .simple-element {
        line-height: 2 !important;
      }

      .column.right {
        align-self: center;
        display: block;

        .wrapper {
          color: $white;
          padding-right: 100px;
          float: right;
          position: relative;
          text-align: left;
          padding-top: 20px;

          // AKA Mobile
          @include mobile {
            padding-left: 20px;
            padding-right: 20px;
            float: left;
          }

          // AKA Tablet
          @include tablet {
            padding-left: 100px;
            padding-right: 100px;
            float: left;
            bottom: 0px;
          }

          @include desktop {
            bottom: 0px;
            padding-left: 20px;
          }

          @include fullhd {
            bottom: 0px;
            position: relative;
            padding-left: 20px;
          }

          .company {
            padding-bottom: 30px;
            font-weight: $weight-bolder;
          }

          .info-slogan {
            padding-top: 30px;

            @media screen and (max-width: 425px){
              display: none;
            }
          }
        }
      }
    }

    .footer {
      z-index: 5;
      position: absolute;
      bottom: 0px;
      background-color: transparent !important;
      padding-left: 50px;
      padding-right: 100px;
      padding-bottom: 30px;
      right: 0px;

      p, a {
        font-size: 12px;
      }
      @include mobile {
        padding-left: 20px;
        padding-right: 20px;
        text-align: left;
      }
    }
  }

  @include mobile {
    a, p, div, .email > a {
      line-height: 120%;
      font-size: 1.25rem;
    }

    .left > a {
      font-size: 1.86rem;

      @media screen and (max-height: 568px){
        font-size: 1.50rem !important;
      }
      line-height: 3rem;
    }
  }

  @include tablet {
    a, p, div {
      line-height: 150%;
      font-size: 1.5rem;
    }

    .left > a { font-size: 4rem; line-height: 5rem;}
  }

@include device (ipad-landscape) {
  .left > a {font-size: 2.7rem; line-height: 4.5rem;}
  }

  @include device (ipad-portrait) {
  .left > a {font-size: 2.5rem; line-height: 4.5rem;}
  }

  @include desktop {
    .left > a { font-size: 2.5rem; line-height: 150%; }
  }

  @include fullhd {
    a, p, div {
      font-size: 1.5rem;
    }

    .left > a { line-height: 170%; }
  }

  @include widescreen {
    a, p, div {
      font-size: 1.5rem;
    }

    .left > a { font-size: 3rem; line-height: 190%; }
  }
}

  a.link{
       display: contents;
       color: $white !important;
     }
  a.link:hover{
       color: $white !important;
     }
 .contacts {
  a, span {
    font-size: 16px !important;
  }
 }
</style>
