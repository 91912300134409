<template lang="pug">
#Footer(ref="footer")
  .mainfooter.has-text-left
    .columns.is-multiline.main-content-footer(ref="columns")
      .column.col1.is-4-desktop.is-full-tablet.has-text-left-desktop.is-hidden-mobile.hidden-balloon
        img.blimp(src="../../assets/images/BigBlimp.svg")
      .column.col2.is-2-desktop.is-full-tablet.has-text-left-desktop
        .row-1.has-text-black
          h3.bolder Esplora
          router-link.footer-link(to="/mobile-app") Applicazioni Mobile
          router-link.footer-link(to="/digitalization") Digitalizzazione
        .row-2.mobile-padding
          h3.bolder.has-text-black Assistenza
          a.footer-link(
            href="https://support.smartsquad.io"
          ) Accedi
      .column.col3.is-2-desktop.is-full-tablet.has-text-left-desktop.has-text-black
        h3.bolder Contatti
        .position
          router-link.footer-link(to="/") Smart Squad Srl
          .positions
            h2.footer-link-subtitle Sede legale
            a.footer-link Via Villalta, 38
            a.footer-link Udine
          .positions
            h2.footer-link-subtitle Sede operativa
            a.footer-link Via Napoleonica, 1
            a.footer-link Castions di Strada
        a.footer-link(href="tel:04321638681") +39 0432 1638681
        a.footer-link(href="mailto:team@smartsquad.io") team@smartsquad.io
      .column.col4.is-2-desktop.is-full-tablet.has-text-left-desktop.has-text-black
        h3.bolder.has-text-black Prodotti
        a.footer-link(
          href='https://inksquad.com/'
          target="_blank"
        ) Inksquad
      .column.col5.is-2-desktop.is-full-tablet.has-text-left-desktop.has-text-black
        h3.bolder Note legali
        a.footer-link(href="https://www.iubenda.com/privacy-policy/86449130") Terms & Conditions
        a.footer-link(
          href="https://www.iubenda.com/privacy-policy/86449130/cookie-policy"
        ) Cookie Policy
      .row.has-text-left.copyright.has-text-black
        .columns.is-multiline.final-row(ref="columns")
          .column.is-10-desktop.is-full-tablet.is-full.mobile
            b © Smart Squad Srl »&nbsp
            | P.IVA IT02898170309
          .column.is-2-desktop.is-full-tablet.is-full-mobile.por
            // img(src="http://www.regione.fvg.it/rafvg/export/sites/default/RAFVG/economia-imprese/industria/FOGLIA203/FOGLIA1/allegati/loghiPOR.jpg")
</template>

<script>
import FooterLogo from '@/components/FooterLogo.vue'

export default {
  name: 'Footer',

  components: {
    FooterLogo
  },

  mounted () {
    const airshipMovment = this.$scrollmagic
      .scene({
        triggerHook: 1,
        duration: 200,
        triggerElement: '#Footer',
        offset: 300
      })
      .setTween('.blimp', { css: { right: -100, top: -40 } })
    // .addIndicators({ name: 'Step 0 (duration: 300)' })
    const airshipOpacity = this.$scrollmagic
      .scene({
        triggerHook: 1,
        duration: 200,
        triggerElement: '#Footer',
        offset: 300
      })
      .setTween('.blimp', { css: { opacity: 0.3 } })

    this.$scrollmagic.addScene(airshipMovment)
    this.$scrollmagic.addScene(airshipOpacity)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

.mainfooter {
  z-index: 1;
}

.main-content-footer{
    padding: 80px 100px 0px;

    @include device(iphone-portrait) {
      padding: 90px 20px 30px;
    }
}

.col1,
.col2,
.col3,
.col4,
.col5 {
  position: relative;

  @include device(iphone-portrait) {
    bottom: 0px;
  }
  @include device(iphone-landscape) {
    bottom: 0px;
  }
}

#Footer {
  display: flex;
  width: 100vw;
  margin-bottom: 0px !important;
  z-index: 999999999;

  .columns {
    margin: 0px;
    // background-image: url("../../assets/StarsBackground.png") !important;
    background: #fff;

    .column {
      padding-top: 0px !important;
      padding-bottom: 60px !important;

      @include mobile {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }
      @include tablet {
        padding-top: 20px !important;
        padding-bottom: 8px !important;
      }
      @include device(ipad-portrait) {
        width: 50% !important;
      }
      @include device(ipad-landscape) {
        width: 25% !important;
      }
    }
  }

  .hidden-balloon{
      @include device(ipad-portrait) {
        display: none;
      }
      @include device(ipad-landscape) {
        display: none;
      }
      @include device(iphone-portrait) {
        display: none;
      }
      @include device(iphone-landscape) {
        display: none;
      }
  }

  .mobile-padding{
  padding-bottom: 25px;
  }

  .footer-link {
    font-weight: 600;
    color: #000 !important;
  }

  .mainfooter {
    margin: auto;
    width: auto;
    z-index: 1;

    @include mobile {
      width: 100%;
    }

    p {
      color: #000 !important;
      font-size: 1.2rem;
      font-weight: 600;
      padding-bottom: 25px;

      @include device(ipad-landscape) {
        font-size: 1rem !important;
      }
      @media screen and (max-width: 766px) {
        font-size: 1rem !important;
      }
    }

    .copyright {
      width: 100% !important;
      display: block;
      text-align: center;
      padding-bottom: 30px;
      padding-top: 30px;
    }

    a:hover {
      color: #000;
    }

    a {
      font-size: 1.2rem;
      color: #000;

      @include device(ipad-landscape) {
        font-size: 1rem !important;
      }
    }

    img {
      width: 250px;
      margin-top: 20px;

    @include device(iphone-portrait) {
      margin: auto;
      text-align: center !important;
    }
    }

    .phoneNumber {
      padding-top: 3px;
    }

    h3.bolder {
      font-family: 'San Francisco Black'
    }

    a.small-link {
      font-size: 1rem !important;
      font-weight: 400;
    }

    .position{
      padding-bottom: 20px;
      .footer-link-subtitle {
        font-size: 16px;
        padding: 5px 0px;
        padding-bottom: 5px;
        padding-top: 20px;
      }
      a.footer-link{
      padding-bottom: 5px;
      }
    }

    a.footer-link {
      display: block;
      padding-bottom: 25px;

      b {
        font-family: "Mont";
        font-weight: $weight-bolder;

        @include mobile {
          font-size: 1.5rem;
        }
        @include desktop {
          font-size: 1.5rem;
        }
        @include fullhd {
          font-size: 1.5rem;
        }
        @include widescreen {
          font-size: 1.5rem;
        }
      }
    }

    h3 {
      padding-bottom: 20px;
      font-weight: 600;

      @include tablet {
        padding-top: 20px;
        font-size: 1.4rem;
      }
      @include mobile {
        font-size: 1.2rem;
        padding-top: 45px;
      } // = 35px
      @include desktop {
        font-size: 1.4rem;
      } // = 52px
      @include fullhd {
        font-size: 1.4rem;
      } // = 80px
      @include widescreen {
        font-size: 1.4rem;
      } // = 80px
      @include device(ipad-landscape) {
        font-size: 1rem;
      }
    }

    .address {
      padding-bottom: 50px;
    }
    .city {
      padding-top: 50px;
      padding-bottom: 15px;
    }
  }
}

img.blimp {
  z-index: 1 !important;
  position: relative;
  height: auto;
  width: 300px !important;
  top: -500px;
  right: 120vw;
  margin-top: -300px;
}
@media screen and (max-width: 1366px) {
  img.blimp {
    display: none;
  }
}

.final-row{
  align-items: baseline;
}
.por{
  text-align: center;
}

.cta{
    color: rgba(0,0,0,1);
    cursor: pointer;
    background: rgba(255,255,255,1);
    padding: 15px 30px;
    max-width: fit-content;
    border: solid 3px #000;
    border-radius: 40px;
    z-index: 9999999;
    font-weight: $weight-bolder !important;
      @include mobile {
      font-size: 16px  !important;}

}

.cta:hover{

    color: rgba(255,255,255,1) !important;
    cursor: pointer;
    background: rgba(0,0,0,1);
    border: solid 3px #000;
    border-radius: 40px;

}
</style>
