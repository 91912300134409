<template lang="pug">
#bottom-bar
  .columns(v-if="!isMobile")
    .left.column.has-text-left.is-12-mobile
     //oundIcon(
        v-on:click.native="toggleSound($event)"
        v-bind:is-active="isAudioActive"
      )
  .column.is-12.has-text-left#bar-for-mobile(v-if="isMobileLandscape")
    RotateIcon#rotate-icon
    span.has-text-white Ruota il device per un'esperienza d'uso ottimale
</template>

<script>
import Logo from '@/components/Logo.vue'
import SoundIcon from '@/components/SoundIcon.vue'
import RotateIcon from '@/components/RotateIcon.vue'

export default {
  name: 'BottomBar',

  components: { Logo, SoundIcon, RotateIcon },

  props: {
    isMobile: { type: Boolean },
    isMobileLandscape: { type: Boolean },
    isMobilePortrait: { type: Boolean }
  },

  data () {
    return {
      isAudioActive: true,
      sound: undefined
    }
  }

  // watch: {
  //   isMobile: function (newVal, oldVal) { // watch it
  //     // console.log(`watch update: ${newVal}`)
  //     // console.log(this.isAudioActive)
  //     if (!newVal) {
  //       if (this.isAudioActive) {
  //         this.sound.play()
  //       }
  //     } else {
  //       if (!this.isAudioActive) {
  //         this.sound.stop()
  //       }
  //     }
  //   }
  // },

  // mounted: function () {
  //   // Setup the new Howl.
  //   const sound = new Howl({
  //     src: ['SmartSquadTheme.mp3'],
  //     loop: true,
  //     volume: 0.5
  //   })

  //   this.sound = sound

  //   // console.log(`mounted: ${this.isMobile}`)
  //   if (!this.isMobile) {
  //     // Play the sound.
  //     sound.play()
  //   }
  // },

  // methods: {
  //   toggleSound: function (event) {
  //     // console.log('toggleSound')
  //     this.isAudioActive = !this.isAudioActive

  //     if (this.sound) {
  //       if (this.isAudioActive) {
  //         this.sound.play()
  //       } else {
  //         this.sound.pause()
  //       }
  //     }
  //   }
  // },

  // // Vue Idle
  // onIdle () {
  //   if (this.isAudioActive && !this.isMobile) {
  //     this.sound.pause()
  //   }
  // },

  // onActive () {
  //   if (this.isAudioActive && !this.isMobile) {
  //     this.sound.play()
  //   }
  // }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

.columns:last-child {
  margin-bottom: 0px;
}

#rotate-icon {
  margin-bottom: -14px;
  margin-right: 20px;
}

#bar-for-mobile {
  position: fixed;
  width: 100%;
  bottom: 0;
  padding-left: 20px;
  padding-bottom: 20px;

  @include device(iphone-portrait) {
  display: none;
  }

}

.columns {
  z-index: 4;
  align-items: center;
  width: 120px;
  display: flex;
  padding-top: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
  padding-left: 25px;
  position: fixed;
  bottom: 0px;
  margin: 0px;

  .lateral {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    text-orientation: inherit;
    padding-left: 20px;
    align-content: center;
    display: inline-grid;
  }

  .left {
    max-width: 55px;
    p {
      margin-right: 10px;
      display: inline-block;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .center {
    text-align: left;
    font-size: 12px;
    p {
      text-shadow: 0px 0px 30px black;
      text-align: left;
    }
  }

  .right {
    img {
      height: 50px;
    }
  }

  .column {
    padding: 0px;
    height: 40px;
    align-items: center;
    display: flex;
  }

  @media screen and (max-width: $tablet) {
    .left {
      margin-bottom: 22px;
      max-width: unset;
    }

    .center {
      p {
        text-shadow: 0px 0px 30px black;
      }
    }

    .first-divider {
      display: inherit;
      height: 0px;
      color: transparent;
    }
  }
}
</style>
