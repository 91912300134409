<template lang="pug">
  #ecw
    #white
    StarsCanvas(
      :id="starsCanvasId"
      :animation="animation",
      :animationSpeed="animationSpeed"
    )
    ScrollIcon.has-text-centered.hide-on-mobile-landscape#scroll-icon

    Header2Section(
      id="section1",
      h2="Realizzaziamo ecommerce e siti web aziendali"
    )
    NoOctaContentSection.hide-on-mobile-landscape(
      id="section2",
      :h2s="['Creiamo siti web che convertono']",
      :ps="['Una presenza digitale forte e consolidata si origina da un <b>progetto web in grado di massimizzare la user experience</b> attraverso approcci e soluzioni innovative che convertono i visitatori di un sito in clienti.']"
    )
    Step2.hide-on-mobile-landscape
    Header2BiggerSection.hide-on-mobile-landscape(
      id="section3",
      :h2s="['APPROCCIO', 'PROGETTUALE']",
      :ps="['In un sito web o ecommerce in grado di raggiungere il massimo grado di conversione, ogni dettaglio tecnico, grafico, testuale e di usabilità viene studiato con cura e dedizione da <b>una squadra di professionisti</b>. Le <b>eccellenti performance certificate</b> e il <b>responsive</b> sono solo due dei valori che garantiamo in modo imprescindibile ai nostri clienti al fine di offrire all’utente finale la migliore esperienza d’uso possibile. Un’interfaccia utente di qualità superiore coniugata alla semplicità d’uso presente anche nelle procedure più complesse di checkout e acquisto completano le performance qualitative offerte dalle nostre soluzioni web.', 'Realizziamo soluzioni web lungimiranti, in linea con i più recenti standard tecnologici adatte a supportare tutti i device: Smartphone, Tablet, Desktop o Smart TV.', 'L’accurata <b>analisi iniziale di progettualità</b> è una delle peculiarità che ci permette di sviluppare soluzioni scalabili, performanti, disponibili ed in target. Combinata all’<b>elevata usabilità ottimizzata su tutti i dispositivi</b>, dà origine a soluzioni web in grado di fornire all’utente finale la migliore esperienza possibile in ogni situazione.', '<b>Ogni soluzione web è confezionata “su misura”</b>, caratterizzata da un’elevata personalizzazione e dall’utilizzo dei più recenti framework e tecnologie presenti sul mercato, testati e validati dal nostro team di professionisti.']"
    )
    Footer.hide-on-mobile-landscape

</template>

<script>
import StarsCanvas from '@/components/3d/StarsCanvas.vue'
import Header2Section from '@/components/shared/Header2Section.vue'
import NoOctaContentSection from '@/components/shared/NoOctaContentSection.vue'
import Step2 from '@/components/e-commerce-and-web/Step2.vue'
import Header2BiggerSection from '@/components/shared/Header2BiggerSection.vue'
import Footer from '@/components/shared/Footer.vue'
import ScrollIcon from '@/components/ScrollIcon.vue'

export default {
  name: 'ECommerceAndWeb',

  components: {
    // Mobile App components
    StarsCanvas,
    Header2Section,
    NoOctaContentSection,
    Step2,
    Header2BiggerSection,
    Footer,

    // Global components
    ScrollIcon
  },

  data () {
    return {
      animation: true,
      animationSpeed: 1,
      starsCanvasId: 'ecommerceandweb-stars-canvas'
    }
  },

  mounted () {
    const localThis = this

    const mobileAppScene1 = this.$scrollmagic.scene({
      triggerElement: '#ecw',
      triggerHook: 0,
      offset: -1,
      duration: this.$el.clientHeight / 3
    })
      .on('enter leave', function (e) {
        localThis.animation = e.type === 'enter'
      })
      .on('progress', function (e) {
        localThis.animationSpeed = 1 - e.progress
      })
      // .addIndicators({ name: `Home - Scene 1 (duration: ${this.$el.clientHeight / 3})` })

    const mobileAppScene2 = this.$scrollmagic.scene({
      triggerHook: 0.5,
      duration: 300,
      offset: 150
    })
      .setTween('#scroll-icon', { css: { opacity: 0 } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    const whitebackground = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#white', { css: { opacity: 1 } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    this.$scrollmagic.addScene(mobileAppScene1)
    this.$scrollmagic.addScene(mobileAppScene2)
    this.$scrollmagic.addScene(whitebackground)
  },

  metaInfo () {
    return {
      title: 'Smart Squad Srl » Sviluppo App & Web',
      seoTitle: 'ecw'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

#ecw {
  background: #fff;

}

 #white {
    background: #fff;
    z-index: 1;
    opacity: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
  }

#section1 {
  height: 100vh !important;

}

.hero.is-fullheight {
  min-height: 0vh !important;
}

.hero.is-halfheight .hero-body,
.hero.is-fullheight .hero-body,
.hero.is-fullheight-with-navbar .hero-body {
  align-items: start !important;
  display: flex;
}

#ecw {
  overflow: hidden;

  .resize-observer {
    position: absolute;
  }

  .canvas {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 0;
    top: 0px;
    opacity: 1;
    background: #000;
  }

  #scroll-icon {
    z-index: 2;
    position: fixed;
    bottom: 20px;
    display: inline-flex;
    // margin-left: -20px;
    left: calc(50% -  17px);
  }

  #section2,
  #section3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
</style>
