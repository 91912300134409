<template lang="pug">
  #digitalization
    #white
    StarsCanvas(
      :id="starsCanvasId"
      :animation="animation",
      :animationSpeed="animationSpeed"
    )
    //- ScrollIcon.has-text-centered.hide-on-mobile-landscape#scroll-icon

    Header2Section.smaller-sky(
      id="section1",
      h2="System integration e data mobilization per l’accesso ai dati ovunque"
    )
    .white-bg.hide-on-mobile-landscape
      NoOctaContentSection.hide-on-mobile-landscape(
        id="section2",
        :h2s="['']",
        :ps="['Integrare l’<b>ambiente fisico con il digitale è una grande sfida.</b> Sensoristica, dispositivi IoT di rilevazione e periferiche di interazione sono alcune delle componenti che possono essere integrate e sfruttate per rendere <b>un ambiente di lavoro intelligente.</b><br><br>Progettiamo soluzioni che possono <b>catturare dati e trasformarli in automazioni semplici ed efficaci,</b> alla portata dell’uomo e della sua forza lavoro. Soluzioni che portano l’uomo ad interagire con l’ambiente e le macchine in modo attivo, sicuro e più efficiente.<br><br>Maggiori dati a disposizione, elaborati ed utilizzati in modo fruibile e comprensibile, si trasformano in informazioni decisive per compiere le scelte strategiche migliori.']"
      )
        //- :h2s="['Come l’integrazione e l’automazione possono aiutare il tuo business']",
      Step2.hide-on-mobile-landscape
      //- Header2BiggerSection.hide-on-mobile-landscape(
      //-   id="section3",
      //-   :h2s="['APPROCCIO', 'PROGETTUALE']",
      //-   :ps="['Integrare <b>l’ambiente fisico con il digitale è una grande sfida.</b> Sensoristica, dispositivi IoT di rilevazione e periferiche di interazione sono alcune delle componenti che possono essere integrate e sfruttate per rendere <b>un ambiente di lavoro intelligente.</b><br><br>Progettiamo soluzioni che possono <b>catturare dati e trasformarli in automazioni semplici ed efficaci,</b> alla portata dell’uomo e della sua forza lavoro. Soluzioni che portano l’uomo ad interagire con l’ambiente e le macchine in modo attivo, sicuro e più efficiente.<br><br>Maggiori dati a disposizione per essere elaborati e utilizzati in modo fruibile e comprensibile per <b>prendere decisioni fondamentali in modo ponderato,</b> eliminando buona parte dell’incertezza.']"
      //- )
      #form.gif.hide-on-mobile-landscape(ref="form")
        img.bottle(:src="bottle")
      ContactForm.hide-on-mobile-landscape
      Footer.hide-on-mobile-landscape

</template>

<script>
import gif from '../assets/images/BOTTLES.gif'
import StarsCanvas from '@/components/3d/StarsCanvas.vue'
import ContactForm from '@/components/shared/ContactForm.vue'
import Header2Section from '@/components/shared/Header2Section.vue'
import NoOctaContentSection from '@/components/shared/NoOctaContentSection.vue'
import Step2 from '@/components/digitalization/Step2.vue'
import Header2BiggerSection from '@/components/shared/Header2BiggerSection.vue'
import Footer from '@/components/shared/Footer.vue'
import ScrollIcon from '@/components/ScrollIcon.vue'

export default {
  name: 'Digitalization',

  components: {
    // Mobile App components
    StarsCanvas,
    Header2Section,
    NoOctaContentSection,
    Step2,
    Header2BiggerSection,
    Footer,

    // Global components
    ScrollIcon,
    ContactForm
  },

  data () {
    return {
      animation: true,
      animationSpeed: 1,
      starsCanvasId: 'digitalization-stars-canvas',
      bottle: gif
    }
  },

  mounted () {
    const localThis = this

    const mobileAppScene1 = this.$scrollmagic.scene({
      triggerElement: '#digitalization',
      triggerHook: 0,
      offset: -1,
      duration: this.$el.clientHeight / 3
    })
      .on('enter leave', function (e) {
        localThis.animation = e.type === 'enter'
      })
      .on('progress', function (e) {
        localThis.animationSpeed = 1 - e.progress
      })
      // .addIndicators({ name: `Home - Scene 1 (duration: ${this.$el.clientHeight / 3})` })

    const mobileAppScene2 = this.$scrollmagic.scene({
      triggerHook: 0.5,
      duration: 300,
      offset: 150
    })
      .setTween('#scroll-icon', { css: { opacity: 0 } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    const whitebackground = this.$scrollmagic.scene({
      triggerHook: 0.8,
      duration: 500,
      offset: 150
    })
      .setTween('#white', { css: { opacity: 0 } })
      // .addIndicators({ name: 'Step 0 (duration: 300)' })

    this.$scrollmagic.addScene(mobileAppScene1)
    this.$scrollmagic.addScene(mobileAppScene2)
    this.$scrollmagic.addScene(whitebackground)
  },

  metaInfo () {
    return {
      seoTitle: 'digitalization'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/app.scss";

  .bottle{
    position: relative;
    z-index: 1;
    width: 170px;
    transform: rotate(45deg);
  }

#section1 {
    height: 75vh !important;
          @include mobile {
    height: 100vh !important;
  }
          @include device(iphone-landscape)  {
    height: 100vh !important;
  }
}

  .white-bg{
    background: #fff !important;
    position: sticky;
  }
  .smaller-sky{
    height: 75% !important;
    @include device(iphone-landscape) {
    height: 100% !important;
  }
  }

#digitalization {
  overflow: hidden;
  background: #fff;

   #white {
    background: #fff;
    z-index: 1;
    opacity: 0;
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: none;

       @include device(iphone-portrait) {
    display: block;
    position: absolute;
  }
  @include device(iphone-landscape) {
    display: block;
    position: absolute;
  }

         @include device(ipad-portrait) {
    display: block;
    position: absolute;
  }
  @include device(ipad-landscape) {
    display: block;
    position: absolute;
  }
  }

  .resize-observer {
    position: absolute;
  }

  .canvas {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 0;
    top: 0px;
    opacity: 1;
    background: #000;
  }

  #scroll-icon {
    z-index: 2;
    position: fixed;
    bottom: 20px;
    display: inline-flex;
    // margin-left: -20px;
    left: calc(50% -  17px);
  }

      #section2 {
      padding-top: 100px;
  }

  #section2,
  #section3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.hide-on-mobile{

      @include device(iphone-portrait) {
display: none;
  }
  @include device(iphone-landscape) {
display: none;
  }
}
</style>
