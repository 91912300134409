import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Digitalization from '../views/Digitalization.vue'
import MobileApp from '../views/MobileApp.vue'
import Process from '../views/Process.vue'
import ECommerceAndWeb from '../views/ECommerceAndWeb.vue'
import Responsiveness from '../views/Responsiveness.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    alias: '',
    component: Home
  },
  {
    path: '/processo',
    name: 'Processo',
    alias: '',
    component: Process
  },
  {
    path: '/mobile-app',
    name: 'Mobile App',
    alias: '',
    component: MobileApp
  },
  {
    path: '/e-commerce-and-web',
    name: 'Ecommerce & Web',
    alias: '',
    component: ECommerceAndWeb
  },
  {
    path: '/digitalization',
    name: 'Digitalization',
    alias: '',
    component: Digitalization
  },
  {
    path: '/responsiveness',
    name: 'Test',
    alias: '',
    component: Responsiveness
  },
  { path: '**', redirect: '/home' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

export default router
